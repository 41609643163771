import React from "react";
import { styled, Typography } from "@mui/material";

const BodyText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  [theme.breakpoints.up("lg")]: {
    width: "80%",
  },
}));

export const ContentSecondSection = () => {
  return (
    <>
      <Typography variant="h2" sx={{ mb: 5 }}>
        ¿Multas o sanciones administrativas?
      </Typography>
      <BodyText sx={{ mb: 5 }} variant="body2">
        Con esta reforma laboral se harán auditorías a todos los proveedores
        REPSE y de no estar en cumplimiento{" "}
        <span style={{ fontWeight: 900 }}>tu cliente </span>
        podría ser acreedor a sanciones y multas millonarias al ser “responsable
        solidario”.
      </BodyText>

      <BodyText variant="body2">
        Con nuestra plataforma tú, sin ayuda de terceros, puedes brindarle
        confianza y tranquilidad a tus clientes ya que valida de forma
        automática (con robots) y resguarda la documentación necesaria para
        demostrar tu cumplimiento.
      </BodyText>
    </>
  );
};
