import ReactMarkdown from "react-markdown";
import { BlogArticle } from "../../types";
import { parseBlogDate, replaceAll } from "../../utils/helpers";

const BlogPost = ({ article, onClose }: { article: BlogArticle; onClose: any }) => {
  const styles: Record<string, React.CSSProperties> = {
    article: {
      width: "90%",
      margin: "0 auto",
      borderRadius: "16px",
      backgroundColor: "#F5FBFF",
      position: "relative",
      paddingTop: "8rem",
    },
    articleCategory: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "85%",
      fontSize: 20,
      color: "#fff",
      display: "flex",
      paddingLeft: "1rem",
      alignItems: "center",
    },
    articleDate: { position: "absolute", right: 0, top: "3.5rem", paddingRight: "3rem" },
    articleCloseBtn: { all: "unset", cursor: "pointer", padding: "4px", position: "absolute", right: "12px", top: "10px" },
    title: { textAlign: "center", fontSize: 24, fontWeight: "bold" },
    image: { margin: "2rem auto 0 auto", display: "block", width: "60%", height: "400px", objectFit: "cover" },
  };

  const markdown = replaceAll(article.contenido, "\n", "&nbsp;  \r\n");

  return (
    <div style={styles.article}>
      <div style={{ position: "absolute", left: 0, top: "3rem" }}>
        <svg width="341" height="48" viewBox="0 0 341 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H341L318 24.5L341 48H0V0Z" fill={article.categoria?.color} />
        </svg>
        <span style={styles.articleCategory}>{article.categoria?.nombre}</span>
      </div>
      <div style={styles.articleDate}>{parseBlogDate(article.published_at)}</div>

      <button style={styles.articleCloseBtn} onClick={onClose}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#03264C" />
        </svg>
      </button>

      <h2 style={styles.title}>{article.titulo}</h2>
      <img style={styles.image} src={"https://kivit-blog.axeleratum.com" + article.imagen?.url} alt="IMSS Infonavit" />
      <div style={{ marginBottom: "2rem", padding: "3rem 5rem" }}>
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </div>
    </div>
  );
};

export default BlogPost;
