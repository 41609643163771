import React from "react";
import { Box, styled, Typography } from "@mui/material";

const Counter = styled("span")(({ theme }) => ({
  border: `2px solid #52C6BA`,
  borderRadius: "50%",
  minWidth: 50,
  height: 50,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 24,
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

const functexts = [
  "Das de alta a tus clientes y el servicio pactado a realizar.",
  "Subes los documentos que te pedimos de cada cliente.",
  "Nuestros robots verifican y validan que sean correctos.",
  "Recibes una notificación de que estás en cumplimiento o de que hay un error y puedas corregirlo.",
  "Tu cliente también recibe una notificación de tu cumplimiento para que le brindes confianza y tranquilidad de trabajar contigo.",
  "Cada mes deberás actualizar la información que te pida la plataforma a través de notificaciones.",
];

export const WorksSection = (props: any) => {
  return (
    <>
      <Typography variant="h2" sx={{ mb: 4 }}>
        ¿Cómo funciona?{" "}
      </Typography>
      {functexts.map((text: string, index: number) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            mb: 4,
            ml: 2,
          }}
          key={text.slice(0, 15)}
        >
          <Counter
            sx={{
              color: props.color ?? "#0092F6",
              borderColor: props.color ?? "#0092F6",
            }}
          >
            {index + 1}
          </Counter>
          <Typography
            sx={{
              display: "inline-flex",
              maxWidth: 400,
            }}
          >
            {text}
          </Typography>
        </Box>
      ))}
    </>
  );
};
