export function parseBlogDate(dateString: string): string {
  const date = new Date(dateString);
  return capitalizeFirstLetter(
    date.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "short",
    })
  ).replace(" de ", " ");
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}