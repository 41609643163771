import { Button } from "@mui/material";
import { BlogArticle } from "../../types";
import { parseBlogDate } from "../../utils/helpers";

const BlogCard = ({ article, onSelect, className = "" }: { article: BlogArticle; onSelect: any; className?: string }) => {
  const styles: Record<string, React.CSSProperties> = {
    card: { width: "30%", minWidth: "275px", maxWidth: "300px", borderRadius: "16px", overflow: "hidden", backgroundColor: "#F5FBFF" },
    cardHeader: {
      backgroundColor: article.categoria?.color,
      padding: "5px",
      color: "white",
      fontWeight: "lighter",
      fontSize: 22,
      textAlign: "center",
    },
    cardImage: {
      display: "block",
      width: "100%",
      height: "160px",
      objectFit: "cover",
    },
    cardBodyHeader: { display: "flex", justifyContent: "space-between", alignItems: "start", padding: ".8rem 0" },
    cardButtonContainer: { display: "flex", justifyContent: "center", padding: ".8rem 0", color: article.categoria?.color },
  };

  return (
    <div style={styles.card} className={className}>
      <div style={styles.cardHeader}>{article.categoria?.nombre}</div>
      <img style={styles.cardImage} src={"https://kivit-blog.axeleratum.com" + article.imagen?.formats?.small?.url} alt={article.slug}/>
      <div style={{ padding: "0 1rem" }}>
        <div style={styles.cardBodyHeader}>
          <span style={{ fontSize: 20, fontWeight: "bold" }}>{article.titulo}</span>
          <span style={{ fontSize: "14px", textAlign: "right" }}>{parseBlogDate(article.published_at)}</span>
        </div>
        <div style={{ textAlign: "center", fontSize: 16, minHeight: "120px" }}>{article.descripcion}</div>
        <div style={styles.cardButtonContainer}>
          <Button onClick={onSelect} variant="outlined" color="inherit" size="large" style={{ width: "90%", borderWidth: "3px" }}>
            Leer Más
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
