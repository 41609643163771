import React, { FC, useCallback } from "react";
import { Hidden, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Menu, ShoppingCart } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TryButton } from "./TryButton";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { appRoutes } from "../App";
import { routeNames } from "../utils/nav-items";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu2 from '@mui/material/Menu';
import Fade from '@mui/material/Fade';

interface NavBarProps {
  handleOpen: () => void;
}

const NavContainer = styled(Box)(({ theme }) => ({
  height: 80,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "fixed",
  top: 0,
  left: 0,
  padding: "0 120px",
  zIndex: 100,
  backgroundImage: "url(/static/backmenu.svg)",
  [theme.breakpoints.up("xl")]: {
    padding: "0 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "0 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0 80px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 30px",
  },
}));

const LogoContainer = styled("img")(({ theme }) => ({
  /*  backgroundColor: "tomato", */
  width: 200,
  height: 30,
  [theme.breakpoints.down("md")]: {
    width: 120,
    height: 25,
  },
  [theme.breakpoints.down("sm")]: {
    width: 100,
    height: 20,
  },
}));

const Microsoft = styled("img")(({ theme }) => ({
  height: 30,
  marginLeft: 20,
  marginRight: 20,
  [theme.breakpoints.down("md")]: {
    height: 25,
  }
}));

const NavItemLink = styled(Link)(({ theme }) => ({
  fontWeight: 700,
  textDecoration: "none",
  color: theme.palette.text.primary,
  wordWrap: "break-word",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 110,
  },
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const NavBtnLink = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  textDecoration: "none",
  color: theme.palette.text.primary,
  wordWrap: "break-word",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 110,
  },
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "10px",
  marginLeft: 16,
  width: 35,
  height: 35,
}));

const ShopButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: "#FFF",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: theme.palette.secondary.primary,
  },
}));

export const NavBar: FC<NavBarProps> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const xsScreen = useMediaQuery(theme.breakpoints.down(550));
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getLogo = useCallback(() => {
    const ph = location.pathname;
    if (
      ph.startsWith(`/${routeNames.home}`) ||
      ph.startsWith(`/${routeNames.corpMicrosoft}`) ||
      ph.startsWith(`/${routeNames.corp}`) ||
      ph.startsWith(`/${routeNames.pymeMicrosoft}`) ||
      ph.startsWith(`/${routeNames.pyme}`)
    ) {
      return {
        asset: "/static/kivit.png",
        hash: "#home",
      };
    } else {
      return {
        asset: "/static/LOGO.svg",
        hash: "#home",
      };
    }
  }, [location.pathname]);

  const renderButtonOne = useCallback(() => {
    const ph = location.pathname;
    if (ph.startsWith(`/${routeNames.corpMicrosoft}`)) {
      return (
        !xsScreen && (
          <TryButton
            onClick={() => navigate(`${location.pathname}/registro`)}
            text="Cotiza tu plan"
            sx={(theme: any) => ({
              bgcolor: "#7678ED",
              "&:hover": { bgcolor: "#7678ED" },
              [theme.breakpoints.up("md")]: {
                minWidth: 120,
              },
            })}
          />
        )
      );
    }
    if (ph.startsWith(`/${routeNames.corp}`)) {
      return (
        <TryButton
          onClick={() => navigate(`${location.pathname}/registro`)}
          text="Cotiza tu plan"
          sx={(theme: any) => ({
            bgcolor: "#7678ED",
            "&:hover": { bgcolor: "#7678ED" },
            [theme.breakpoints.up("md")]: {
              minWidth: 120,
            },
          })}
        />
      );
    }
    if (ph.startsWith(`/${routeNames.pymeMicrosoft}`)) {
      return (
        !xsScreen && (
          <TryButton
            onClick={() => navigate(`${location.pathname}/registro`)}
            text="Prueba gr               atis"
            sx={{
              bgcolor: "#7678ED",
              "&:hover": { bgcolor: "#7678ED" },
              minWidth: 120,
              ml: 1,
            }}
          />
        )
      );
    }
    if (ph.startsWith(`/${routeNames.pyme}`)) {
      return (
        <TryButton
          onClick={() => navigate(`/${routeNames.pyme}/registro`)}
          text="Prueba gratis"
          sx={{
            bgcolor: "#7678ED",
            "&:hover": { bgcolor: "#7678ED" },
            minWidth: 120,
            ml: 1,
          }}
        />
      );
    }
    if (ph.startsWith("/v0")) {
      return xsScreen ? (
        <ShopButton onClick={() => navigate(`/registro`)}>
          <ShoppingCart />
        </ShopButton>
      ) : (
        <TryButton onClick={() => navigate(`/registros`)} text="Prueba gratis" />
      );
    }
    if (ph.startsWith("/pyme-v0")) {
      return <TryButton onClick={() => navigate(`${location.pathname}/registro`)} text="Prueba gratis" />;
    }
    if (ph.startsWith(`/${routeNames.home}`)) {
      return (
        !xsScreen && (
          <TryButton
            onClick={() => window.location.assign("https://kivit.axeleratum.com/")}
            text="Iniciar sesión"
            sx={{
              "&:hover": { bgcolor: "#7678ED" },
              bgcolor: "#7678ED",
              minWidth: 120,
            }}
          />
        )
      );
    }
    return null;
  }, [location.pathname, navigate, xsScreen]);

  const renderButtonTwo = useCallback(() => {
    const ph = location.pathname;
    if (ph.startsWith(`/${routeNames.pyme}`) || ph.startsWith(`/${routeNames.corpMicrosoft}`) || ph.startsWith(`/${routeNames.corp}`)) {
      return null;
      /* return (
        !xsScreen && (
          <TryButton
            link="https://materialidad.axeleratum.com"
            text="Iniciar Sesión"
            sx={(theme: any) => ({
              bgcolor: "#52C6BA",
              "&:hover": { bgcolor: "#52C6BA" },
              [theme.breakpoints.down("md")]: {
                transform: "translateX(20px)",
              },
              [theme.breakpoints.up("md")]: {
                minWidth: 120,
                ml: 3,
              },
            })}
          />
        )
      ); */
    }
    if (ph.startsWith("/v0")) {
      return null;
      /* return (
        !xsScreen && (
          <TryButton
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.primary.main,
              marginLeft: 2,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
              },
            })}
            text="Iniciar sesión"
            link="https://materialidad.axeleratum.com"
          />
        )
      ); */
    }
    if (ph.startsWith("/pyme-v0")) {
      return null;
      /* return (
        !xsScreen && (
          <TryButton
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.primary.main,
              marginLeft: 2,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
              },
            })}
            text="Iniciar sesión"
            link="https://materialidad.axeleratum.com"
          />
        )
      ); */
    }
    // if (ph.startsWith(`/${routeNames.home}`)) {
    //   return (
    //     !xsScreen && (
    //       <TryButton
    //         onClick={() => navigate(`/${routeNames.corp}`)}
    //         text="Soy Corporativo"
    //         sx={{
    //           bgcolor: "#52C6BA",
    //           "&:hover": { bgcolor: "#52C6BA" },
    //           minWidth: 120,
    //           ml: { xs: 1, sm: 2, md: 5 },
    //         }}
    //       />
    //     )
    //   );
    // }
    return null;
  }, [location.pathname]);

  const getNavItems = () => {
    if (location.pathname === "/" || location.pathname === "/kivit-blog") {
      return appRoutes[0];
    }
    const navs = appRoutes.find((route: any) => location.pathname.slice(1).startsWith(route.path));
    return navs ?? appRoutes[0];
  };

  const ProveedorMenu: () => JSX.Element = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
      <NavBtnLink 
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={(theme: any) => ({
          [theme.breakpoints.down("sm")]: { fontSize: 4 },
        })}
      >
        Plan Proveedor
        <ExpandMoreIcon/>
      </NavBtnLink>
      <Menu2
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={(theme: any) => ({
          [theme.breakpoints.down("sm")]: { fontSize: 4 },
          mt: "1px", "& .MuiMenu-paper": 
          { backgroundColor: "whiteSmoke"}
        })}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(`/proveedor`)}>Inicio</MenuItem>
        <MenuItem onClick={() => navigate(`/proveedor#repse`)}>REPSE</MenuItem>
        <MenuItem onClick={() => navigate(`/proveedor#works`)}>¿Como funciona?</MenuItem> 
        <MenuItem onClick={() => navigate(`/proveedor#benefits`)}>¿Detalles del plan?</MenuItem>
       {/**<MenuItem onClick={() => navigate(`/#contact`)}>Contacto</MenuItem>  */} 
      </Menu2>
    </div>
    );
  } 

  const CorporativoMenu: () => JSX.Element = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
      <NavBtnLink
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Plan Corporativo 
        <ExpandMoreIcon/>
      </NavBtnLink>
      <Menu2
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        sx={(theme: any) => ({
          [theme.breakpoints.down("sm")]: { fontSize: 6 },
          mt: "1px", "& .MuiMenu-paper": 
          { backgroundColor: "whiteSmoke"}, 
        })}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(`/corporativo`)}>Inicio</MenuItem>
        <MenuItem onClick={() => navigate(`/corporativo#responsable`)}>Responsable solidario</MenuItem>
        <MenuItem onClick={() => navigate(`/corporativo#benefits`)}>Características</MenuItem>
        <MenuItem onClick={() => navigate(`/corporativo#banner`)}>Detalles del plan</MenuItem>
      </Menu2>
    </div>
    );
  }
  return (
    <NavContainer>
      <Box style={{ display: "flex", justifyContent: "space-between", gap: "1.4rem" }}>
        {/*location.pathname !== "/pyme-v0" && (
          <Link to={getNavItems().path === "/" ? `${getNavItems().path}#home` : `/${getNavItems().path}#home`}>
            <LogoContainer alt="Logo" src={getLogo().asset} />
          </Link>
        )}
        {(location.pathname.startsWith(`/${routeNames.pymeMicrosoft}`) || location.pathname.startsWith(`/${routeNames.corpMicrosoft}`)) && (
          <Box style={{ borderLeft: "3px solid #000" }}>
            <Microsoft alt="Microsoft" src="/static/microsoft.png" />
          </Box>
        )*/}
         <Link to={"/"}>
            <LogoContainer alt="Logo" src={getLogo().asset} />
          </Link>
      </Box>
      {/* @ts-ignore */}
      <Hidden smDown>
        {/*getNavItems().navItems.map((navItem: any) => {
          if (getNavItems().path === "/") {
            return (
              <NavItemLink to={`${getNavItems().path}${navItem.hash}`} key={navItem.hash}>
                {navItem.name}
              </NavItemLink>
            );
          }
          return (
            <NavItemLink to={`/${getNavItems().path}${navItem.hash}`} key={navItem.hash}>
              {navItem.name}
            </NavItemLink>
          );
        })*/}
        
        <NavItemLink to={`/#benefits`}>Beneficios</NavItemLink>
         <ProveedorMenu/>
         <CorporativoMenu/>
        <NavItemLink to={`/#contact`}>Contacto</NavItemLink>
      </Hidden>

      <Box
        sx={(theme: any) => ({
          [theme.breakpoints.down("sm")]: {
            ml: "auto",
          },
        })}
      >
        {/*xsScreen && renderButtonOne()*/}
        {/*!xsScreen && renderButtonOne()*/}
        {/*!smScreen && renderButtonTwo()*/}
        <TryButton
            onClick={() => window.location.assign("https://kivit.axeleratum.com/")}
            text="Iniciar sesión"
            sx={{
              "&:hover": { bgcolor: "#7678ED" },
              bgcolor: "#7678ED",
              minWidth: 120,
            }}
          />
        
        {smScreen && (
          <MenuButton onClick={props.handleOpen}>
            <Menu color="primary" />
          </MenuButton>
        )}
      </Box>
    </NavContainer>
  );
};
