import React, { FC } from "react";
import { Button } from "@mui/material";
import { alpha, styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const ButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: 25,
  whiteSpace: "nowrap",
  textTransform: "none",
  minHeight: 45,
  width: 120,
  [theme.breakpoints.up("xs")]: {
    fontSize: 18,
    width: 160,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 20,
    width: 170,
    padding: "5px 40px",
  },
  [theme.breakpoints.up("md")]: {
    width: 230,
  },
}));

interface MainButtonProps {
  link: string;
  text: string;
  external?: boolean;
  color?: string;
  type?: "fill" | "outline";
  sx?: any;
  onClick?: () => void;
}

export const MainButton: FC<MainButtonProps> = ({ children, ...props }) => {
  const navigate = useNavigate();

  const getStyles = () => {
    switch (props.type) {
      case "outline":
        if (props.color === "purple") {
          return {
            border: "1px solid #7678ED",
            bgcolor: "transparent",
            color: "#7678ED",
            "&:hover": {
              bgcolor: "#7678ED",
              color: "transparent",
            },
          };
        } /* (props.color==='green') */ else {
          return {
            border: "1px solid #52C6BA",
            bgcolor: "transparent",
            color: "#52C6BA",
            "&:hover": {
              bgcolor: "#52C6BA",
              color: "#FFF",
            },
          };
        }

      case "fill":
        if (props.color === "purple") {
          return {
            bgcolor: "#7678ED",
            color: "#FFF",
            "&:hover": {
              bgcolor: alpha("#7678ED", 0.7),
            },
          };
        } /* (props.color==='green') */ else {
          return {
            bgcolor: "#52C6BA",
            color: "#FFF",
            "&:hover": {
              bgcolor: alpha("#52C6BA", 0.7),
            },
          };
        }
        default:
          return {
            border: "1px solid #6CB4EE",
            bgcolor: "#6CB4EE",
            color: "#FFF",
            "&:hover": {
              bgcolor: alpha("#6CB4EE", 0.7),
              color: "#FFF",
            },
          };
    }
  };
  if (props.external) {
    return (
      <a
        href={props.link}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <ButtonStyled
          sx={{
            ...getStyles(),
            ...(props.sx ?? {}),
          }}
        >
          {props.text}
        </ButtonStyled>
      </a>
    );
  }

  return (
    <ButtonStyled
      sx={{
        ...getStyles(),
        ...(props.sx ?? {}),
      }}
      onClick={props.onClick ?? (() => navigate(props.link))}
    >
      {props.text}
    </ButtonStyled>
  );
};
