import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usePageGa from "../../hooks/usePageGa";
import { useScrollToTop } from "../../hooks/useScrollToTop";

import { BlogArticle } from "../../types";
import BlogPost from "./BlogPost";
import PostsCarousel from "./PostsCarousel";

export const KivitBlog = () => {
  useScrollToTop();
  usePageGa()

  const isDesktop = useMediaQuery("(min-width: 900px)");
  const isMedium = useMediaQuery("(min-width: 600px)");
  const [articulos, setArticulos] = React.useState<BlogArticle[]>([]);
  const [selectedArticle, setSelectedArticle] = React.useState<BlogArticle | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  function handleSelect(article: BlogArticle) {
    navigate("#" + article.slug);
  }

  useEffect(() => {
    fetch("https://kivit-blog.axeleratum.com/articulos")
      .then((response) => response.json())
      .then((data) => {
        setArticulos(data);
      });
  }, []);

  useEffect(() => {
    const art = articulos.find((a) => a.slug === location.hash.replace("#", ""));
    if (art) {
      setSelectedArticle(art);
    } else {
      setSelectedArticle(null);
    }
  }, [location, articulos]);

  return (
    <>
      <MainContainer id="blog">
        <div style={{width:'100%',display:'flex',justifyContent:'center', marginTop: "1.8rem" }}>
          {Boolean(selectedArticle) ? (
            <BlogPost article={selectedArticle!} onClose={() => navigate("/kivit-blog")} />
          ) : articulos?.length ? (
            <PostsCarousel articulos={articulos} onSelect={handleSelect} slidesToScroll={isDesktop ? 3 : isMedium ? 2 : 1} />
          ) : (
            <div>Cargando...</div>
          )}
        </div>
      </MainContainer>
    </>
  );
};

const MainContainer = styled(Box)(({ theme }: any) => ({
  width: "100%",
  minHeight: "70vh",
  backgroundImage: 'url("/static/main-background.svg")',
  backgroundSize: "cover",
  backgroundRepeat: "repeat-y",
  display: "flex",
  paddingTop:"100px",
  paddingBottom:"80px"

}));
