import React, { FC } from "react";
import { Box, Grid, styled } from "@mui/material";
import { useLocation } from "react-router-dom";

const SectionContainer = styled(Box)(({ theme }) => ({
  /* height:"100vh", */
  width: "100%",
  padding: "80px 120px",
  display: "flex",
  overflow: "hidden",
  [theme.breakpoints.up("xl")]: {
    padding: "0 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "60px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "60px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "60px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "40px 30px",
  },
}));

const Image = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    transform: "scale(80%)",
  },
  [theme.breakpoints.down("md")]: {
    transform: "scale(90%)",
  },
  [theme.breakpoints.down("sm")]: {
    transform: "scale(95%)",
  },
}));

interface SecondSectionProps {
  idHash: string;
  reverse?: boolean;
  reverseColumn?: boolean;
  img?: string;
  cols?: number;
  styleImg?: object;
}

export const SecondSection: FC<SecondSectionProps> = (props) => {
  const ref = React.useRef<HTMLDivElement>();
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === "#" + props.idHash) {
      if (ref?.current) {
        ref?.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location, props.idHash]);
  return (
    <SectionContainer id={props.idHash} ref={ref}>
      <Grid
        container
        spacing={5}
        alignItems="center"
        justifyContent="center"
        sx={{ flexDirection: { sm: props.reverse ? "row" : "row-reverse" } }}
      >
        <Grid
          item
          xs={12}
          sm={props.cols ?? 6}
          sx={(theme: any) => ({
            [theme.breakpoints.down("sm")]: {
              order: props.reverseColumn ? 1 : 0,
            },
          })}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%" } }}>{props.children}</Box>
        </Grid>
        <Grid item xs={8} sm={props.cols ? 12 - props.cols : 6}>
          <Image
            style={props.styleImg ? props.styleImg : undefined}
            src={props.img ? `/static/${props.img}` : "/static/pic2.png"}
          />
        </Grid>
      </Grid>
    </SectionContainer>
  );
};
