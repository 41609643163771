import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { FC } from "react";

const SecurityPoliticDialog = ({ onClose }: any) => {
  function handleDownload() {
    window.open(window.location.origin + "/terminos-y-condiciones-kivit.pdf", "_blank");
  }
  return (
    <Dialog onClose={onClose} open={true} fullWidth maxWidth="lg" PaperProps={{ style: { borderRadius: "12px" } }}>
      <DialogContent
        style={{
          width: "100%",
          padding: "2rem",
          textAlign: "center",
          backgroundColor: "#F5FBFF",
          borderRadius: "50px",
          position: "relative",
        }}
      >
        <>
          <br />
          <Typography variant="h5" style={{ textAlign: "center", fontWeight: "bold", color: "#0092F6" }}>
          POLÍTICA DE SEGURIDAD DE LA INFORMACIÓN KIVIT
          </Typography>
          <PolicyParagraph>
          <strong>Axeleratum</strong> empresa dedicada a diseñar, desarrollar y comercializar soluciones para el cumplimiento y
          orquestación de procesos documentales ha decidido implantar un Sistema de Gestión de la Seguridad de la 
          Información basado en la norma ISO 27001.
          </PolicyParagraph>

          <PolicyParagraph>
          El objetivo de esta iniciativa es preservar la confidencialidad, integridad y disponibilidad de la información y 
          protegerla de un amplio grupo de amenazas. Este Sistema de Gestión está destinado a asegurar la continuidad de las 
          líneas de negocio, minimizar posibles daños, maximizar el retorno de las inversiones, ampliar las oportunidades de 
          negocio y asegurar la mejora continua de sus productos y servicios.
          </PolicyParagraph>

          <PolicyParagraph>
          La Dirección de <strong>Axeleratum</strong> es consciente de que la información es un 
          activo que tiene un elevado valor para la organización y requiere por tanto una protección adecuada.
          </PolicyParagraph>

          <PolicyParagraph>
          La Dirección de <strong>Axeleratum</strong> establece como objetivos de base y principios de la seguridad de la 
          información los siguientes:
          </PolicyParagraph>

          <PolicyParagraph>
            <li > La protección de los datos de carácter personal y la intimidad de las personas.</li>  
            <li > La salvaguarda de los registros de la organización.</li>    
            <li > La protección de los derechos de propiedad intelectual.</li>    
            <li > La documentación de la política de seguridad de la información.</li>    
            <li > La asignación de responsabilidades de seguridad.</li>    
            <li > La formación y capacitación de todo el personal para la seguridad de la información.</li>    
            <li > El registro de las incidencias de seguridad.</li>    
            <li > La gestión de la continuidad del negocio.</li>    
            <li > La gestión de los cambios que pudieran darse en la empresa relativos a la seguridad.</li>    
          </PolicyParagraph>

          <PolicyParagraph>
          La Dirección de <strong>Axeleratum</strong>,mediante la elaboración e implantación del Sistema de Gestión de Seguridad 
          de la Información adquiere los siguientes compromisos:
          </PolicyParagraph>

          <PolicyParagraph>
             Desarrollar productos y servicios conformes con los requisitos legislativos,
             identificando para ello las normas que apliquen a las líneas de negocio desarrolladas por la organización e incluidas en 
              el alcance del Sistema de Gestión de la Seguridad de la Información   
          </PolicyParagraph>
          <PolicyParagraph>
          
            <li > Establecer y cumplir los requisitos contractuales con las partes interesadas.</li>    
            <li > Definir los requisitos de formación en seguridad y proporcionar la formación necesaria en dicha materia
             a las partes interesadas mediante el establecimiento de planes de formación.</li>    
            <li > Prevenir y detectar virus y otro software malicioso, mediante el desarrollo de políticas específicas
             y el establecimiento de acuerdos contractuales con organizaciones especializadas..</li>    
            <li > Gestionar la continuidad del negocio, desarrollando planes 
            de continuidad conformes a metodologías de reconocido prestigio internacional.</li>    
            <li > Establecer las consecuencias de las violaciones de la política de seguridad, 
            las cuales serán reflejadas en los contratos firmados con las partes interesadas, proveedores y subcontratistas.</li>    
            <li > Actuar en todo momento dentro de la más estricta ética profesional.</li>    
          </PolicyParagraph>

          <PolicyParagraph>
          Esta Política proporciona el marco de referencia para el establecimiento de objetivos y de mejora 
          continua del Sistema de Gestión de Seguridad de la Información. Es comunicada a toda la Organización a través del 
          gestor documental instalado en la organización y su publicación en paneles informativos, siendo revisada anualmente para su adecuación y 
          extraordinariamente cuando concurran situaciones especiales y/o cambios sustanciales en el Sistema de Gestión de Seguridad de la Información, 
          estando a disposición público en general. 
          </PolicyParagraph>

         {/** <Button size="large" onClick={handleDownload} style={{ marginTop: "20px" }}>
            Descargar
          </Button>*/} 
        </>

        <Button
          onClick={onClose}
          variant="text"
          color="inherit"
          style={{ position: "absolute", top: "1rem", right: "1rem", fontSize: "1rem" }}
        >
          X
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SecurityPoliticDialog;

const PolicyParagraph: FC = ({ children }: any) => {
  return (
    <Box fontSize={16} textAlign="justify" marginTop="16px" marginBottom="16px">
      {children}
    </Box>
  );
};

interface PolicyListProps {
  type?: "a" | "i" | "1" | "A" | "I";
}

const PolicyList: FC<PolicyListProps> = ({ children, type }) => {
  return (
    <ol type={type} style={{ paddingInlineStart: 20 }}>
      {children}
    </ol>
  );
};
