import useEmblaCarousel from "embla-carousel-react";
import { OptionsType } from "embla-carousel/components/Options";
import { useCallback, useEffect, useState } from "react";

export default function useCarousel(options: Partial<OptionsType & { onSelect: (...args: any) => any }> = {}) {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const internalOnSelect = useCallback(
    (args: any) => {
      if (!emblaApi) return;
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setPrevBtnEnabled(emblaApi.canScrollPrev());
      setNextBtnEnabled(emblaApi.canScrollNext());
      options.onSelect && options.onSelect(args);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.scrollTo(selectedIndex, true);
    internalOnSelect(null);
    emblaApi.on("select", internalOnSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, internalOnSelect]);

  return {
    emblaRef,
    emblaApi,
    scrollPrev,
    scrollNext,
    prevBtnEnabled,
    nextBtnEnabled,
    selectedIndex,
    setSelectedIndex
  };
}
