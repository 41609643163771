import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MainButton } from "../../components/MainButton";
import { useLocation } from "react-router-dom";

export const ContentMainSection = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  return (
    <>
      {smScreen ? (
        <Typography variant="h2" sx={{ mb: 3 }} align="left">
          Protege la integridad de tu empresa
        </Typography>
      ) : (
        <>
          <Typography
            variant="h2"
            sx={{ fontSize: { sm: 50, md: 55, lg: 58 } }}
          >
            Protege la integridad
          </Typography>
          <Typography
            variant="h2"
            sx={{ mb: 3, fontSize: { sm: 50, md: 55, lg: 58 } }}
          >
            de tu empresa
          </Typography>
        </>
      )}

      <Typography
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            lg: "80%",
          },
          fontWeight: 500,
        }}
        variant="body2"
      >
        Con nuestra plataforma puedes gestionar y resguardar de manera práctica,
        segura y eficiente los documentos probatorios de cumplimiento legal,
        fiscal y patronal de quienes contratan y/o proveen servicios
        especializados y obras complementarias (outsourcing).
      </Typography>
      <Box
        sx={(theme: any) => ({
          display: "flex",
          gap: 3,
          [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
          },
        })}
      >
        <MainButton
          //link={`${location.pathname}/registro`}
          link={`/#contact`}
          color="purple"
          type="fill"
          text="Cotiza tu plan"
        />
        <MainButton link="#responsable" color="green" type="outline" text="Saber más" />
      </Box>
    </>
  );
};
