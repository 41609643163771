import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
} from "@mui/material";
import { globalStyles } from "./globalStyles";
import { typography } from "./typography";
import { breakpoints } from "./breakpoints";
import { palette } from "./palette";

export const ThemeConfig = ({ children }: any) => {
  let theme = createTheme({
    typography,
    breakpoints,
    palette,
    shape: { borderRadius: 8 },
  });
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={(theme) => {
          return globalStyles(theme);
        }}
      />
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
