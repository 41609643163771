import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === "" || hash === "#home") {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);
};
