import React, { FC } from "react";
import { Grid, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";

interface MainSectionProps {
  img?: string;
  link?: string;
}

const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  backgroundImage: 'url("/static/main-background.svg")',
  backgroundSize: "cover",
  backgroundRepeat: "repeat-y",
  padding: "80px 120px",
  display: "flex",
  overflow: "hidden",
  [theme.breakpoints.up("xl")]: {
    padding: "0 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "60px 120px",
    paddingTop: 120,
  },
  [theme.breakpoints.down("lg")]: {
    padding: "60px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "60px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "60px 30px",
    paddingTop: 120,
  },
}));

const MainImage = styled("img")(({ theme }) => ({
  transform: "scale(125%)",
  [theme.breakpoints.between("xs", "sm")]: {
    transform: "scale(130%)",
  },
  [theme.breakpoints.between("sm", "md")]: {
    transform: "scale(135%)",
  },
  [theme.breakpoints.between("md", "lg")]: {
    transform: "scale(120%)",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    transform: "scale(110%)",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 60,
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: 40,
  },
}));

export const MainSection: FC<MainSectionProps> = (props) => {
  const ref = React.useRef<HTMLDivElement>();
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === "#home") {
      if (ref?.current) {
        ref?.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <MainContainer id="home" ref={ref}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={7} lg={6} xl={7}>
          <Box>{props.children}</Box>
        </Grid>
        <Grid item xs={7} sm={5} lg={6} xl={5}>
          {props.link ? (
            <a href={props.link} target="_blank" rel="noreferrer">
              <MainImage
                src={props.img ? `/static/${props.img}` : "/static/pic1.png"}
              />
            </a>
          ) : (
            <MainImage
              src={props.img ? `/static/${props.img}` : "/static/pic1.png"}
            />
          )}
        </Grid>
      </Grid>
    </MainContainer>
  );
};
