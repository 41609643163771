import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { ScrollToTop } from "../components/ScrollToTop";
import { Privacy } from "../components/Privacy";
import TermsDialog from "../components/TermsDialog";
import PrivacyDialogCorp from "../components/PrivacyDialog";

export const Faqs = ({ type }: { type: "terms" | "privacy" | "privacy-corp" }) => {
  const [openPrivacy, setopenPrivacy] = React.useState(type === "privacy");
  const [openPrivacyCorp, setopenPrivacyCorp] = React.useState(type === "privacy-corp");
  const [openTerms, setopenTerms] = React.useState(type === "terms");

  const handleShowModal = () => {
    if (type === "terms") {
      setopenTerms(true);
    } else if (type === "privacy") {
      setopenPrivacy(true);
    } else {
      setopenPrivacyCorp(true);
    }
  };

  return (
    <>
      <Box sx={{ minHeight: "calc(80vh - 160px)" }}>
        <ScrollToTop />
        <Typography variant="h2" align="center" sx={{ mt: "160px", mb: "80px" }}>
          {type === "terms"
            ? "Términos y condiciones"
            : type === "privacy-corp"
            ? "Política de privacidad"
            : "Política de privacidad de datos"}
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleShowModal} variant="contained" size="large">
            Ver{" "}
            {type === "terms"
              ? "términos y condiciones"
              : type === "privacy-corp"
              ? "política de privacidad"
              : "política de privacidad de datos"}
          </Button>
        </div>
      </Box>
      {openPrivacy && (
        <Modal open={true} onClose={() => setopenPrivacy(false)}>
          <Privacy onClose={() => setopenPrivacy(false)} />
        </Modal>
      )}
      {openTerms && <TermsDialog open={true} onClose={() => setopenTerms(false)} />}
      {openPrivacyCorp && <PrivacyDialogCorp open={true} onClose={() => setopenPrivacyCorp(false)} />}
    </>
  );
};
