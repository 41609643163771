import React, { useState } from "react";
import { Box } from "@mui/material";
import { Drawer } from "./Drawer";
import { NavBar } from "./NavBar";
import { Outlet, useNavigate } from "react-router-dom";
import { Footer } from "./Footer";

export const CommonLayot = () => {
  const [openDrawer, setopenDrawer] = useState(false);
  const navigate = useNavigate();

  const handleOpenDrawer = () => {
    setopenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setopenDrawer(false);
  };

  const handleOpenPrivacy = () => {
    navigate("/faqs/privacy");
  };


  return (
    <Box>
      <NavBar handleOpen={handleOpenDrawer} />
      <Drawer open={openDrawer} handleClose={handleCloseDrawer} />
      <Outlet />
      <Footer handleOpenPrivacy={handleOpenPrivacy} />
    </Box>
  );
};
