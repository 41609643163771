import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import { routeNames } from "../utils/nav-items";

const BenefitsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "whitesmoke",
  width: "100%",
  maxWidth: 1800,
  margin: "auto",
  padding: "0 30px",
  [theme.breakpoints.up("xl")]: {
    padding: "50px 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "50px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "50px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "50px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "50px 30px",
  },
}));

const Card = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  maxWidth: 300,
  minHeight: 200,
  backgroundColor: "white",
  padding: "15px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 10,
  position: "relative",
  marginBottom: 12,
  margin: "0 auto 12px auto",
  [theme.breakpoints.down(550)]: {
    minHeight: 210,
  },
  [theme.breakpoints.down(500)]: {
    padding: "10px 15px",
    "& p:first-of-type": {
      fontSize: 14,
    },
  },
  [theme.breakpoints.down(375)]: {
    minHeight: 180,
    padding: 0,
  },
}));

// const CardCaption = styled(Typography)(({ theme }) => ({
//   fontSize: 10,
//   position: "absolute",
//   color: "#888",
//   bottom: -15,
//   whiteSpace: "nowrap",
//   zIndex: 99,
//   [theme.breakpoints.down(550)]: {
//     whiteSpace: "normal",
//     bottom: -35,
//   },
//   [theme.breakpoints.between(375, 385)]: {
//     bottom: -35,
//   },
//   [theme.breakpoints.down(375)]: {
//     whiteSpace: "normal",
//     bottom: -25,
//   },
//   [theme.breakpoints.down(350)]: {
//     whiteSpace: "normal",
//     bottom: -35,
//   },
// }));

export const BenefitsSection = (props: any) => {
  const location = useLocation();
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (location.hash === "#benefits") {
      if (ref?.current) {
        ref?.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  // const getFooterCard = (i: number) => {
  //   switch (location.pathname) {
  //     case "/":
  //       if (i === 4) {
  //         return (
  //           <CardCaption
  //             variant="caption"
  //             align="center"
  //             sx={{
  //               fontSize: 10,
  //               position: "absolute",
  //               color: "#888",
  //               bottom: -15,
  //             }}
  //           >
  //             *Se puede incrementar por un costo extra
  //           </CardCaption>
  //         );
  //       } else if (i === 7) {
  //         return (
  //           <CardCaption variant="caption" align="center">
  //             *Costo extra: $250MXP por 50 trabajadores y $500MXP por 100.
  //           </CardCaption>
  //         );
  //       } else if (i === 8) {
  //         return (
  //           <CardCaption variant="caption" align="center">
  //             *Se puede incrementar por un costo extra.
  //           </CardCaption>
  //         );
  //       } else {
  //         return null;
  //       }
  //     case routeNames.pyme:
  //       if (i === 4) {
  //         return (
  //           <CardCaption
  //             variant="caption"
  //             align="center"
  //             sx={{
  //               fontSize: 10,
  //               position: "absolute",
  //               color: "#888",
  //               bottom: -15,
  //             }}
  //           >
  //             *Se puede incrementar por un costo extra
  //           </CardCaption>
  //         );
  //       } else {
  //         return null;
  //       }
  //     case `/${routeNames.pymeMicrosoft}`:
  //       if (i === 7) {
  //         return (
  //           <CardCaption variant="caption" align="center">
  //             *Si tienes más de 100 trabajadores solicita tu cotización
  //             personalizada
  //           </CardCaption>
  //         );
  //       } else {
  //         return null;
  //       }

  //     default:
  //       return null;
  //   }
  // };
  const getBenefitsArray = () => {
    switch (location.pathname) {
      case "/":
      case "/v0":
        return benefitsDesc;
      case "/pyme-v0":
        return benefitsDescPyme;
      case `/${routeNames.pyme}`:
      case `/${routeNames.pymeMicrosoft}`:
        return benefitsKivitPymeMicrosoft;
      case `/${routeNames.corp}`:
      case `/${routeNames.corpMicrosoft}`:
        return benefitsKivitCorp;
      default:
        return [];
    }
  };
  const getTitle = () => {
    switch (location.pathname) {
      case "/":
      case "/v0":
      case "/pyme-v0":
      case `/${routeNames.pyme}`:
        return "Beneficios";
      case `/${routeNames.pymeMicrosoft}`:
        return "Beneficios";
      case `/${routeNames.corp}`:
      case `/${routeNames.corpMicrosoft}`:
        return "Conoce las características de nuestro producto";
      default:
        return "";
    }
  };
  return (
    <BenefitsContainer ref={ref} id="benefits">
      <Typography variant="h3" align="center" sx={{ mb: 5 }}>
        {getTitle()}
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {getBenefitsArray().map((ben, i: number) => (
          <Grid item xs={6} sm={4} key={i}>
            <Card>
              <img alt="" src={`/static/B${i + 1}.svg`} style={{ width: 60 }} />
              <Typography
                sx={{ fontWeight: 500 }}
                variant="body1"
                align="center"
              >
                {ben.text}
              </Typography>
              {ben.text2 && (
                <div style={{ marginTop: "-10px", fontSize: ".9rem" }}>
                  {ben.text2}
                </div>
              )}
              {/* {getFooterCard(i)} */}
            </Card>
          </Grid>
        ))}
      </Grid>
    </BenefitsContainer>
  );
};

const benefitsDesc = [
  {
    text: `El diseño de la plataforma te facilita la identificación y organización de tus documentos.`,
    text2: null,
  },
  {
    text: `La plataforma registra todas las acciones en tus documentos. (Trazabilidad de procesos)`,
    text2: null,
  },
  {
    text: `Tus documentos son validados y verificados de forma automatizada por medio de robots.`,
    text2: null,
  },
  {
    text: `Te notificamos oportunamente sobre las posibles multas y/o sanciones con el tablero de riesgos.`,
    text2: null,
  },
  {
    text: `Hasta 7 GB de almacenamiento* en la bóveda de Blockchain con seguridad máxima (inmutables).`,
    text2: null,
  },
  {
    text: `Transparencia con tus clientes al estar en cumplimiento con IMSS, SAT, STPS, Infonavit, Código Fiscal.`,
    text2: null,
  },
  {
    text: `Acompañamiento remoto el primer mes y soporte técnico de Lunes a Viernes de 09 – 18 hrs.`,
    text2: null,
  },
  {
    text: `Tienes la posibilidad de administrar los CFDI de nómina de hasta 25 trabajadores.*`,
    text2: null,
  },
  {
    text: `Añade hasta 5 miembros* de tu equipo en caso de que requieras apoyo.`,
    text2: null,
  },
  {
    text: `Capacidad ilimitada de agregar el número de clientes que tu empresa requiera.`,
    text2: null,
  },
];

const benefitsDescPyme = [
  {
    text: "Fácil identificación y organización de tus documentos.",
    text2: null,
  },
  {
    text: "Registro de todos los movimientos realizados (Trazabilidad).",
    text2: null,
  },
  {
    text: "Validación y verificación automatizada de la información cargada.",
    text2: null,
  },
  {
    text: "Notificaciones para actualizar periódicamente la documentación.",
    text2: null,
  },
  {
    text: "Hasta 20 GB* de almacenamiento en una bóveda de blockchain.",
    text2: null,
  },
  {
    text: "Posibilidad de crear accesos ilimitados para tu equipo de trabajo.",
    text2: null,
  },
  {
    text: "Atención al cliente de Lunes a Viernes de 09:00 a 17:30 horas.",
    text2: null,
  },
  {
    text: "Administración de los CFDI de nómina de hasta 100 trabajadores.*",
    text2: null,
  },
  {
    text: "Posibilidad de crear accesos ilimitados para tu equipo de trabajo.",
    text2: null,
  },
  {
    text: "Capacidad ilimitada para agregar los clientes que tu empresa requiera.",
    text2: null,
  },
];

const benefitsKivitCorp = [
  {
    text: "Identificación y organización de tus documentos de forma ágil.",
    text2: null,
  },
  {
    text: "Trazabilidad: registra todos los movimientos realizados.",
    text2: null,
  },
  {
    text: "Validación y verificación automatizada de tus documentos.",
    text2: null,
  },
  {
    text: "Sistema de notificaciones e identificación de riesgos.",
    text2: null,
  },
  { text: "Infraestructura basada en tecnología Blockchain.", text2: null },
];

const benefitsKivitPymeMicrosoft = [
  {
    text: "Fácil identificación y organización de tus documentos.",
    text2: null,
  },
  {
    text: "Registro de todos los movimientos realizados (Trazabilidad).",
    text2: null,
  },
  {
    text: "Validación y verificación automatizada de la información cargada.",
    text2: null,
  },
  {
    text: "Notificaciones para actualizar periódicamente la documentación.",
    text2: null,
  },
  {
    text: "Hasta 20 GB* de almacenamiento en una bóveda de blockchain.",
    text2: "* incrementa por un costo extra",
  },
  {
    text: "Capacidad ilimitada para que agregues a tantos clientes como desees.",
    text2: null,
  },
  {
    text: "Atención al cliente de Lunes a Viernes de 09:00 a 17:30 horas.",
    text2: null,
  },
  {
    text: "Administración de los CFDI de nómina de tus trabajadores.",
    text2: null,
  },
  {
    text: "Posibilidad de crear accesos ilimitados para tu equipo de trabajo.",
    text2: null,
  },
];
