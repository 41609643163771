import React, { FC } from "react";
import { Button } from "@mui/material";
import { alpha, styled } from "@mui/system";

const OrangeButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color?: string }>(({ theme, color }) => ({
  backgroundColor: color ?? theme.palette.secondary.main,
  color: "#FFF",
  padding: "5px 10px",
  fontWeight: 700,
  textDecoration: "none",
  textTransform: "none",
  "&:hover": {
    backgroundColor: color ? alpha(color, 0.8) : theme.palette.secondary.dark,
  },
  /* [theme.breakpoints.down('')] */
}));

interface TryButtonProps {
  text?: string;
  link?: string;
  sx?: any;
  fullWidth?: boolean;
  onClick?: () => void;
}

export const TryButton: FC<TryButtonProps> = ({ children, ...props }) => {
  if (!props.link) {
    return (
      <OrangeButton
        onClick={props.onClick}
        fullWidth={props.fullWidth}
        sx={props.sx}
      >
        {props.text ?? "Comienza tu prueba gratis"}
      </OrangeButton>
    );
  }
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <OrangeButton
        onClick={props.onClick}
        fullWidth={props.fullWidth}
        sx={props.sx}
      >
        {props.text ?? "Comienza tu prueba gratis"}
      </OrangeButton>
    </a>
  );
};
