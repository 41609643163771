import React from "react";
import { Typography } from "@mui/material";
import { TryButton } from "../../components/TryButton";
import { useNavigate } from "react-router-dom";

export const ContentMainSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <Typography
        sx={{
          mb: 2,
          color: "primary.main",
          letterSpacing: "1px",
          width: { xs: "80%", sm: "100%" },
        }}
        variant="h6"
      >
        ¿Eres una PyME que provee servicios de{" "}
        <i style={{ letterSpacing: "5px" }}>outsourcing</i>?
      </Typography>
      <Typography variant="h2">Evita riesgos fiscales,</Typography>
      <Typography variant="h2">legales, laborales y</Typography>
      <Typography variant="h2" sx={{ mb: 2 }}>
        económicos…
      </Typography>
      <Typography
        sx={(theme: any) => ({
          fontWeight: 500,
          [theme.breakpoints.down("sm")]: {
            width: "80%",
          },
          [theme.breakpoints.down(400)]: {
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "60%",
          },
          [theme.breakpoints.up("xl")]: {
            width: "40%",
          },
        })}
        variant="body2"
      >
        Protege la integridad de tu empresa de manera práctica, segura y
        eficiente con nuestra tecnología que de manera automatizada administra,
        verifica y resguarda los documentos para dar cumplimiento con el REPSE.
      </Typography>
      <TryButton
        sx={{ mt: 5, fontSize: { sm: 20 }, px: { sm: 5 } }}
        onClick={() => navigate("/registro")}
        text="Comienza tu prueba gratis"
      />
    </>
  );
};
