import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TryButton } from "../../components/TryButton";

export const ContentMainSection = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  return (
    <>
      {smScreen ? (
        <Typography variant="h1" sx={{ mb: 3 }} align="center">
          ¿Tu PyME es REPSE?
        </Typography>
      ) : (
        <>
          <Typography variant="h1">¿Tu PyME </Typography>
          <Typography variant="h1" sx={{ mb: 3 }}>
            es REPSE?
          </Typography>
        </>
      )}

      <Typography
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            lg: "80%",
          },
          fontWeight: 500,
        }}
        variant="body2"
      >
        Digitaliza y automatiza la gestión laboral de tu empresa con Kivit. Nuestra
        plataforma te ayuda a transparentar tu cumplimiento con las
        disposiciones establecidas con la reforma laboral para la contratación
        de servicios especializados (IMSS, SAT; STPS, Infonavit y Código Civil).
      </Typography>
      <Box
        sx={(theme: any) => ({
          display: "flex",
          gap: 3,
          [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
          },
        })}
      >
        <TryButton
          sx={{
            mt: 3,
            fontSize: { xs: 18, sm: 20 },
            px: { sm: 5 },
            whiteSpace: "nowrap",
            minWidth: { xs: 140, sm: 150, md: "auto" },
            minHeight: 45,
          }}
          onClick={() => navigate("/registro")}
          text="Prueba gratis"
        />
        <Button
          variant="outlined"
          sx={{
            mt: 3,
            fontSize: { xs: 18, sm: 20 },
            px: { sm: 5 },
            whiteSpace: "nowrap",
            textTransform: "none",
            minWidth: { xs: 140, sm: 150, md: "auto" },
            minHeight: 45,
          }}
          /*  onClick={() => navigate("/registro")} */
        >
          Saber más
        </Button>
      </Box>
    </>
  );
};
