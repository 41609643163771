import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MainButton } from "../../components/MainButton";

export const ContentMainSection = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Typography
        variant="h2"
        sx={{ mb: 3 }}
        align={smScreen ? "center" : "left"}
      >
        ¿Cuentas con el registro REPSE?
      </Typography>

      <Typography
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            lg: "80%",
          },
          fontWeight: 500,
        }}
        variant="body2"
      >
        Digitaliza y automatiza la gestión laboral de tu empresa con {<strong style={{fontSize:'24px'}}>Kivit</strong>}. Nuestra
        plataforma te ayuda a transparentar tu cumplimiento con las
        disposiciones establecidas con la reforma laboral para la contratación
        de servicios especializados (IMSS, SAT; STPS, Infonavit y Código Civil).
      </Typography>
      <Box
        sx={(theme: any) => ({
          display: "flex",
          gap: 3,
          [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
          },
        })}
      >
        <MainButton
          link="/#contact"
          color="purple"
          type="fill"
          text="Pide tu DEMO"
        />
        <MainButton
          link="#repse"
          color="green"
          type="outline"
          text="Saber más"
        />
      </Box>
    </>
  );
};
