import { BlogArticle } from "../../types";
import BlogCard from "./BlogCard";
import useCarousel from "../../hooks/useCarousel";
const PostsCarousel = ({ articulos, onSelect, slidesToScroll }: { articulos: BlogArticle[]; onSelect: (article: BlogArticle) => any, slidesToScroll:number }) => {


  const { emblaRef, scrollPrev, scrollNext, nextBtnEnabled, prevBtnEnabled } = useCarousel({
    slidesToScroll,
    skipSnaps: true,
  });

  return (
    <div className="embla" ref={emblaRef} style={{ position: "relative" }}>
      <div className="embla__container" style={{ gap: "7%" }}>
        {articulos?.map((article) => (
          <BlogCard key={article.slug} article={article} onSelect={() => onSelect(article)} className="embla__slide" />
        ))}
      </div>
      <button
        onClick={scrollPrev}
        style={{ all: "unset", cursor: prevBtnEnabled ? "pointer" : "not-allowed", position: "absolute", left: 0, top: "48%" }}
      >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="24" stroke="#03264C" stroke-width="2" />
          <path d="M29 14L17 25.5L29 37" stroke="#03264C" stroke-width="2.5" stroke-linecap="round" />
        </svg>
      </button>
      <button
        onClick={scrollNext}
        style={{ all: "unset", cursor: nextBtnEnabled ? "pointer" : "not-allowed", position: "absolute", right: 0, top: "48%" }}
      >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="24" transform="rotate(-180 25 25)" stroke="#03264C" stroke-width="2" />
          <path d="M21 36L33 24.5L21 13" stroke="#03264C" stroke-width="2.5" stroke-linecap="round" />
        </svg>
      </button>
    </div>
  );
};

export default PostsCarousel;
