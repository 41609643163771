import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { TryButton } from "./TryButton";
import { useLocation, useNavigate } from "react-router-dom";

const BannerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 180,
  backgroundImage: "url(/static/banner-background.svg)",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "40px 70px",
  [theme.breakpoints.down(545)]: {
    /*  flexDirection: "column",
    height: "auto", */
    padding: "20px 50px",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "0 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "20px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "20px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 30px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "20px 15px",
  },
}));

const CashContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down(545)]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("xs")]: {
    justifyContent: "flex-sart",
  },
}));

const Cash = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: 50,
    margin: "10px 0",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: 45,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 70,
  },
}));

const pricesOptions = [
  {
    label: "100", //employees
    value: "1,000", //price
  },
  {
    label: "150",
    value: "2,000",
  },
  {
    label: "200",
    value: "3,000",
  },
  {
    label: "300",
    value: "5,000",
  },
  {
    label: "400",
    value: "7,000",
  },
  {
    label: "500",
    value: "9,000",
  },
];

export const Banner = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (location.hash === "#banner") {
      if (ref?.current) {
        ref?.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  const [price, setPrice] = useState(pricesOptions[0].value);

  return (
    <BannerContainer ref={ref} id="banner">
      {props.corp ? (
        <Box
          sx={(theme: any) => ({
            [theme.breakpoints.down(550)]: {
              width: 200,
            },
          })}
        >
          <Typography variant="h3">Nuestros planes corporativos</Typography>
        </Box>
      ) : (
        <Box
          sx={(theme: any) => ({
            transform: "scale(100%)",
            [theme.breakpoints.down(550)]: {
              width: 160,
              transform: "scale(70%)",
            },
          })}
        >
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: 600,
              letterSpacing: 2,
              textAlign: "center",
              fontSize: { xs: 14, sm: 16 },
            }}
          >
            Conoce nuestro plan mensual desde
          </Typography>
          <CashContainer>
            <Cash>${price}</Cash>
            <div
              style={{
                marginLeft: "5px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography
                sx={{ fontSize: { xs: 15, sm: 20 }, fontWeight: 500 }}
              >
                MXP /
              </Typography>
              <select onChange={(e) => setPrice(e.target.value)}>
                {pricesOptions.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <Typography
                sx={{ fontSize: { xs: 15, sm: 20 }, fontWeight: 500 }}
              >
                Trabajadores
              </Typography>
            </div>
          </CashContainer>
          <Typography
            sx={{ marginTop: "-1rem", fontSize: { xs: 15 }, fontWeight: 500 }}
          >
            *precio de introcucción por tiempo limitado
          </Typography>
        </Box>
      )}

      {!props.corp ? (
        <Box
          sx={(theme: any) => ({
            [theme.breakpoints.down(450)]: {
              transform: "scale(80%)",
            },
            display: "flex",
            flexDirection: "column",
          })}
        >
          <TryButton
            sx={(theme: any) => ({
              width: { sm: props.caption ? 150 : 300 },
              fontSize: { sm: 20 },
              bgcolor:
                props.color === "purple"
                  ? "#7678ED"
                  : theme.palette.secondary.main,
              "&:hover": {
                bgcolor:
                  props.color === "purple"
                    ? "#7678ED"
                    : theme.palette.secondary.main,
              },
            })}
            onClick={() => navigate(`/#contact`)}
            text={props.text ?? "Contáctanos"}
          />
          {/*props.caption && (
            <Typography align="center" sx={{ mt: 1 }}>
             Sólo 30 días 
            </Typography>
          )*/}
        </Box>
      ) : (
        <Box
          sx={(theme: any) => ({
            [theme.breakpoints.down(450)]: {
              transform: "scale(80%)",
            },
            display: "flex",
            flexDirection: "column",
          })}
        >
          <TryButton
            sx={(theme: any) => ({
              width: { sm: props.caption ? 150 : 300 },
              fontSize: { sm: 20 },
              bgcolor:
                props.color === "purple"
                  ? "#7678ED"
                  : theme.palette.secondary.main,
              "&:hover": {
                bgcolor:
                  props.color === "purple"
                    ? "#7678ED"
                    : theme.palette.secondary.main,
              },
            })}
          //  onClick={() => navigate(`/#contact`)}
            onClick={() => navigate(`/#contact`)}
            text={props.text ?? "Contáctanos"}
          />
        </Box>
      )}
    </BannerContainer>
  );
};
