import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";

const Counter = styled("span")(({ theme }) => ({
  border: `2px solid #52C6BA`,
  borderRadius: "50%",
  minWidth: 50,
  height: 50,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 24,
  fontWeight: 600,
  color: "#52C6BA",
}));

export const ContentSecondSection = () => {
  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h2">¿Cómo funciona?</Typography>
        </Grid>
        {arrayWorks.map((ele: string, i: number) => (
          <Grid item xs={12} md={6} sx={{ display: "flex" }} key={i}>
            <Counter>{i + 1}</Counter>
            <Typography sx={{ ml: 4 }}>{ele}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const arrayWorks = [
  "Primero das de alta a tus clientes y el servicio pactado a realizar.",
  "Después subes los documentos que te pedimos de cada cliente.",
  "Luego nuestros robots verifican y validan la información y, en caso de haber algún error, recibes una notificación para que puedas corregirlo.",
  "Finalmente, cada mes deberás actualizar la información que te pida la plataforma a través de notificaciones.",
];
