const PRIMARY = {
  light: "#E8F5F9",
  main: "#0092F6",
};
const SECONDARY = {
  main: "#E59B4E",
  dark: "#D17B1F"
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
};

export const palette = {
  ...COMMON,
  text: {
    primary: "#001633",
  }
};
