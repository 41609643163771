import React, { useEffect } from "react";
import { ContactSection } from "../../components/ContactSection";
import { MainSection } from "../../components/MainSection";
import { SecondSection } from "../../components/SecondSection";
import usePageGa from "../../hooks/usePageGa";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { BenefitsContent } from "./BenefitsContent";
import { ContentMainSection } from "./ContentMainSection";

export const KivitHome = () => {
  useScrollToTop();
  usePageGa()
  return (
    <>
      <MainSection
        img="kivit-pyme-main.png"
        link="https://www.youtube.com/watch?v=WIR2z2vJ4s8&ab_channel=Axeleratum"
      >
      <ContentMainSection />

      </MainSection>
      <SecondSection
        reverseColumn
        idHash="benefits"
        img="benefits-kivit-pyme.png"
      >
      <BenefitsContent />
      </SecondSection>
      
      <ContactSection
        img="contact-kivit1.png"
        email="info@kivit.mx"
        numbers="55 50 93 65 26"
      />
    </>
  );
};
