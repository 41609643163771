export const homeNavItems = [
  {
    name: "REPSE",
    hash: "#repse",
  },
  {
    name: "Suscripción y Beneficios",
    hash: "#benefits",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
];

export const pymeNavItems = [
  {
    name: "REPSE",
    hash: "#repse",
  },
  {
    name: "¿Cómo funciona?",
    hash: "#works",
  },
  {
    name: "Suscripción y Beneficios",
    hash: "#benefits",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
];

//////////////////KIVIT

export const kivitHomeNavItems = [
  {
    name: "Home",
    hash: "#home",
  },
  {
    name: "Beneficios",
    hash: "#benefits",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
 
];

export const kivitPymeNavItems = [
  {
    name: "REPSE",
    hash: "#repse",
  },
  {
    name: "¿Cómo funciona?",
    hash: "#works",
  },
  {
    name: "Detalles del plan",
    hash: "#banner",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
];

export const kivitPymeMicrosoftNavItems = [
  {
    name: "¿Cómo funciona?",
    hash: "#works",
  },
  {
    name: "Detalles del plan",
    hash: "#banner",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
];

export const kivitEnterpriseNavItems = [
  {
    name: "Responsable solidario",
    hash: "#responsable",
  },
  {
    name: "Detalles del plan",
    hash: "#benefits",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
];

export const kivitEnterpriseMicrosoftNavItems = [
  {
    name: "Responsable solidario",
    hash: "#responsable",
  },
  {
    name: "Detalles del plan",
    hash: "#benefits",
  },
  {
    name: "Contacto",
    hash: "#contact",
  },
];

export const routeNames = {
  home: "",
  pyme: "proveedor",
  corp: "corporativo",
  pymeMicrosoft: "pyme-microsoft",
  corpMicrosoft: "corporativo-microsoft",
  plataforma: "https://kivit.axeleratum.com",
  blog:"kivit-blog",
};
