import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

const FeaturesContainer = styled(Box)(({ theme }) => ({
  /* height:"100vh", */
  width: "100%",
  padding: "80px 120px",
  overflow: "hidden",
  [theme.breakpoints.up("xl")]: {
    padding: "0 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "60px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "60px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "60px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "40px 30px",
  },
}));
const Check = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: 50,
  },
}));

export const FeaturesSection = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [plan, setplan] = useState("pro");
  useEffect(() => {
    if (!smScreen) {
      setplan("both");
    } else {
      setplan("pro");
    }
  }, [smScreen]);

  const renderPlan = (p: any) => {
    return (
      <Grid
        item
        xs={6}
        md={4}
        sx={{
          borderRight: p === "pro" && !smScreen ? "2px solid #888" : "none",
        }}
      >
        <Box sx={{ maxWidth: 250, mx: "auto", pl: 2 }}>
          <Box
            sx={{
              minHeight: 80,
              mb: 3,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              align="center"
              variant="h2"
              component={"p"}
              sx={{ lineHeight: 1, fontWeight: 200 }}
            >
              {p === "pro" ? "20 GB" : "40 GB"}
            </Typography>
            <Typography align="center" variant="subtitle2" component={"p"}>
              * Se puede incrementar por un costo extra.
            </Typography>
          </Box>
          <Typography
            align="center"
            variant="h2"
            component={"p"}
            sx={{
              mb: 3,
              minHeight: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 200,
            }}
          >
            {p === "pro" ? "hasta 3" : "4 o más"}
          </Typography>
          <Box
            sx={{
              mb: 3,
              minHeight: 80,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Check
              alt=""
              src="/static/check.svg"
              style={{ margin: "0 auto" }}
            />
          </Box>
          <Box
            sx={{
              mb: 3,
              minHeight: 80,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Check
              alt=""
              src="/static/check.svg"
              style={{ margin: "0 auto" }}
            />
          </Box>
          <Typography
            align="center"
            variant="h2"
            component={"p"}
            sx={{
              mb: 3,
              minHeight: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 200,
            }}
          >
            ilimitada
          </Typography>
          <Typography
            align="center"
            variant="h2"
            component={"p"}
            sx={{
              mb: 3,
              minHeight: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 200,
            }}
          >
            ilimitada
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <FeaturesContainer>
      <Grid container>
        <Grid item container xs={12} sx={{ mt: 2, mb: 4 }}>
          <Grid item xs={6} md={4}></Grid>
          {(plan === "pro" || plan === "both") && (
            <Grid item xs={6} md={4}>
              <Typography
                variant="h5"
                component="p"
                align="center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Plan Pro
                {smScreen && (
                  <IconButton onClick={() => setplan("premium")}>
                    <ArrowCircleRightOutlinedIcon sx={{ color: "#000" }} />
                  </IconButton>
                )}
              </Typography>
            </Grid>
          )}
          {(plan === "premium" || plan === "both") && (
            <Grid item xs={6} md={4}>
              <Typography
                variant="h5"
                component="p"
                align="center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {smScreen && (
                  <IconButton onClick={() => setplan("pro")}>
                    <ArrowCircleLeftOutlinedIcon sx={{ color: "#000" }} />
                  </IconButton>
                )}
                Plan Premium
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} md={4} sx={{ borderRight: "2px solid #888" }}>
          <Box sx={{ maxWidth: 180, mx: "auto", pr: 2 }}>
            {arrFeatures.map((fea: any) => (
              <Typography
                key={fea.slice(0, 15)}
                variant="h5"
                component={"p"}
                sx={{
                  minHeight: 80,
                  mb: 3,
                  fontSize: { xs: "14px", sm: "18px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {fea}
              </Typography>
            ))}
          </Box>
        </Grid>
        {plan === "both" && renderPlan("pro")}
        {plan === "both" && renderPlan("premium")}
        {plan === "pro" && renderPlan("pro")}
        {plan === "premium" && renderPlan("premium")}
      </Grid>
    </FeaturesContainer>
  );
};

const arrFeatures = [
  "Almacenamiento en Blockchain",
  "Cantidad de entidades legales",
  "Acompañamiento remoto",
  "Administración de los CFDI de nómina de tus trabajadores",
  "Capacidad de usuarios para crear mi equipo de trabajo",
  "Cantidad de clientes y/o proveedores que tu empresa requiere",
];
