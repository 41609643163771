import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ThemeConfig } from "./theme/ThemeConfig";
import { CommonLayot } from "./components/CommonLayot";
import { Home } from "./pages/home/Home";
import { Register } from "./pages/Register";
// import { Faqs } from "./pages/Faqs";
import { Pyme } from "./pages/pyme/Pyme";
import { KivitHome } from "./pages/kivit-home/KivitHome";
import {
  homeNavItems,
  kivitEnterpriseMicrosoftNavItems,
  kivitEnterpriseNavItems,
  kivitHomeNavItems,
  kivitPymeMicrosoftNavItems,
  kivitPymeNavItems,
  pymeNavItems,
  routeNames,
} from "./utils/nav-items";
import { KivitPymeMicrosoft } from "./pages/kivit-pyme-microsoft/KivitPymeMicrosoft";
import { KivitCorp } from "./pages/kivit-corp/KivitCorp";
import { KivitCorpMicrosoft } from "./pages/kivit-corp-microsoft/KivitCorpMicrosoft";
import { KivitPyme } from "./pages/kivit-pyme/KivitPyme";

import ReactGA from "react-ga";
import { KivitBlog } from "./pages/kivit-blog/KivitBlog";
import { Faqs } from "./pages/Faqs";
ReactGA.initialize("UA-247338519-1");

export const appRoutes = [
  {
    path: "/",
    page: <KivitHome />,
    navItems: kivitHomeNavItems,
  },
  {
    path: routeNames.pymeMicrosoft,
    page: <KivitPymeMicrosoft />,
    navItems: kivitPymeMicrosoftNavItems,
    
  },
  {
    path: routeNames.pyme,
    page: <KivitPyme />,
    navItems: kivitPymeNavItems,
  },
  {
    path: routeNames.corpMicrosoft,
    page: <KivitCorpMicrosoft />,
    navItems: kivitEnterpriseMicrosoftNavItems,
  },
  {
    path: routeNames.corp,
    page: <KivitCorp />,
    navItems: kivitEnterpriseNavItems,
  },
  {
    path: "/v0",
    page: <Home />,
    navItems: homeNavItems,
  },
  {
    path: "pyme-v0",
    page: <Pyme />,
    navItems: pymeNavItems,
  },
  {
    path: routeNames.blog,
    page: <KivitBlog />,
    navItems: kivitHomeNavItems,
  },
];

const App = () => {
  return (
    <BrowserRouter>
      <ThemeConfig>
        <Routes>
          {appRoutes.map((route: any) => (
            <Route key={route.path} path={route.path} element={<CommonLayot />}>
              <Route index element={route.page} />
              <Route path="registro" element={<Register kivit={route.path !== "v0"} />} />
              <Route path="faqs/terms" element={<Faqs type="terms" />} />
              <Route path="faqs/privacy" element={<Faqs type="privacy" />} />
              <Route path="faqs/privacy/corp" element={<Faqs type="privacy-corp" />} />
            </Route>
          ))}
        </Routes>
      </ThemeConfig>
    </BrowserRouter>
  );
};

export default App;
