import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../utils/nav-items";

// const Whatsapp = styled("img")(({ theme }) => ({
//   maxWidth: 90,
//   position: "fixed",
//   bottom: "10%",
//   right: "5%",
//   zIndex: 1,
//   [theme.breakpoints.down("sm")]: {
//     bottom: "5%",
//     maxWidth: 60,
//   },
// }));

export const ContentMainSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <Typography
        sx={{
          mb: 2,
          color: "primary.main",
          letterSpacing: "1px",
          fontWeight: 600,
          textAlign: { xs: "center", sm: "left" },
        }}
        variant="h5"
      >
        ¿Eres una PyME que provee servicios de{" "}
        <i style={{ letterSpacing: "5px" }}>outsourcing</i>?
      </Typography>
      <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
        Evita riesgos fiscales,
      </Typography>
      <Typography variant="h2" sx={{ textAlign: { xs: "center", sm: "left" } }}>
        legales, laborales y
      </Typography>
      <Typography
        variant="h2"
        sx={{ mb: 2, textAlign: { xs: "center", sm: "left" } }}
      >
        económicos…
      </Typography>
      <Typography
        sx={(theme: any) => ({
          fontWeight: 500,
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            textAlign: "left",
          },
          [theme.breakpoints.down(400)]: {
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "60%",
          },
          [theme.breakpoints.up("xl")]: {
            width: "40%",
          },
        })}
        variant="body2"
      >
        Somos una empresa de tecnología que te ofrece una plataforma digital
        práctica, segura y eficiente, con la cual puedes prepararte para
        auditorías sin ayuda de terceros, así como respaldar estar en
        cumplimiento con el REPSE (IMSS, SAT, STPS, Infonavit, Código Fiscal).
      </Typography>
      <Button
        onClick={() => navigate(`/${routeNames.pyme}`)}
        variant="outlined"
        sx={{
          borderWidth: "2px",
          mt: 5,
          fontSize: { xs: 18, sm: 20 },
          px: { sm: 5 },
          mx: "auto",
          display: { xs: "block", sm: "inline-block" },
          width: { xs: 160, sm: 170, md: 230 },
          minHeight: 45,
        }}
      >
        Conócenos
      </Button>
      {/* <Whatsapp alt="" src="/static/logo-whats.png" /> */}
    </>
  );
};
