import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Theme,
  FormControlLabel,
  Checkbox,
  styled,
  alpha,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { ScrollToTop } from "../components/ScrollToTop";
import { useLocation } from "react-router-dom";
import { routeNames } from "../utils/nav-items";
interface FormData {
  name: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  company: string;
  rfc: string;
}

const RegisterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  marginTop: 86,
  minHeight: "calc(100vh - 80px)",
  padding: "0 30px",
  [theme.breakpoints.up("xl")]: {
    padding: "50px 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "50px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "50px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "50px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 30px 50px 30px",
  },
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": { fontSize: 14 },
  display: "inline-flex",
  alignItems: "center",
  maxWidth: 450,
  color: "#9EA7B0",
}));

const Check = styled(Checkbox)(({ theme }) => ({
  padding: "4px 10px",
  paddingLeft: theme.spacing(1),
}));

export const Register = (props: any) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");

  const handleFaqs = (type: "terms" | "privacy") => {
    if (type === "terms") {
      window.open("/faqs/terms", "_blank")
    } else {
      window.open("/faqs/privacy/corp", "_blank")
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();
  const location = useLocation();

  const handleForm = async (formData: FormData) => {
    if (!acceptedTerms) return alert("Debe aceptar terminos y condiciones");
    setCurrentEmail(formData.email);
    const body = {
      companyName: formData.company,
      rfc: formData.rfc,
      enable: true,
      username: formData.email,
      status: "TEMP",
      platform: "KIVITLANDING",
      name: formData.name + " " + formData.lastname,
      userRfc: " ",
      email: formData.email,
      phone: formData.phoneNumber,
    };
    try {
      const { data: existsMail } = await fetch("https://auth.axeleratum.com/api/v1/users/exists/" + formData.email).then((res) =>
        res.json()
      );
      if (existsMail) {
        return alert("El correo proporcionado ya está registrado");
      }
      setShowSuccessDialog(true);
      await fetch("https://bpm.axeleratum.com/api/v1/bpm/companies/create", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      alert("No se pudo completar la acción");
    }
  };

  const getLabels = () => {
    if (!location.pathname.includes(routeNames.corp)) {
      return {
        name: "Compártenos tu nombre o nombres*",
        lastname: "Compártenos tus apellidos*",
        email: "Compártenos tu correo*",
        phoneNumber: "Compártenos tu número de teléfono*",
        company: "¿Cómo se llama tu empresa PyME?*",
        rfc: "RFC*",
      };
    }

    return {
      name: "Nombre(s)*",
      lastname: "Apellidos*",
      email: "Correo*",
      phoneNumber: "Teléfono*",
      company: "Nombre de la empresa",
      rfc: "RFC*",
    };
  };

  const handleInvitationReady = () => {
    setShowSuccessDialog(false);
    reset({ company: "", email: "", lastname: "", name: "", phoneNumber: "", rfc: "" });
  };

  return (
    <RegisterContainer>
      <ScrollToTop />
      <GridContainer container spacing={2}>
        <Grid item xs={12} sm={7}>
          {location.pathname.startsWith(`/${routeNames.pymeMicrosoft}`) || location.pathname.startsWith(`/${routeNames.pyme}`) ? (
            <Typography sx={{ color: "primary.main", letterSpacing: 3 }} variant="h6">
              Accede para continua
            </Typography>
          ) : (
            <Typography sx={{ color: "primary.main", letterSpacing: 3 }} variant="h6">
              Averigua que plan es para ti
            </Typography>
          )}

          <Typography sx={{ my: 1, fontWeight: 400 }} variant="h2">
            Disfruta los beneficios
          </Typography>
          <Box
            sx={{
              maxWidth: 550,
              mt: 2,
              mb: 1,
            }}
          >
            {location.pathname.startsWith(`/${routeNames.corpMicrosoft}`) || location.pathname.startsWith(`/${routeNames.corp}`) ? (
              <Typography variant="body2" sx={{ mb: 2 }}>
                Queremos atenderte de la mejor forma, por favor llena el formulario y un asesor se pondrá en contacto lo más pronto posible.
              </Typography>
            ) : (
              <>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxWidth: 550,
                    mt: 2,
                    mb: 1,
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>¿Ya tienes cuenta con nosotros?</Typography>
                  <TryButton
                    sx={(theme: Theme) => ({
                      minWidth: 142,
                      backgroundColor: props.kivit ? "#52C6BA" : theme.palette.primary.main,
                      px: 2,
                      borderRadius: "8px",
                      fontSize: { xs: 15, sm: 20 },
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: alpha(props.kivit ? "#52C6BA" : theme.palette.primary.main, 0.8),
                      },
                    })}
                    text="Iniciar sesión"
                    link="https://kivit.axeleratum.com/"
                  />
                </Box>
                <Typography sx={{ maxWidth: 550, fontWeight: 500 }} align="center">
                  o
                </Typography>
                <Typography sx={{ maxWidth: 550, mb: 2, fontWeight: 500 }}>No tengo cuenta</Typography> */}
              </>
            )}
          </Box>

          <TextField
            {...register("name", { required: true })}
            error={!!errors.name}
            fullWidth
            placeholder={getLabels().name}
            sx={{ mb: 2, maxWidth: 550 }}
            InputProps={{ sx: { bgcolor: "#FFF" } }}
          />
          <TextField
            {...register("lastname", { required: true })}
            error={!!errors.lastname}
            fullWidth
            placeholder={getLabels().lastname}
            sx={{ mb: 2, maxWidth: 550 }}
            InputProps={{ sx: { bgcolor: "#FFF" } }}
          />
          <TextField
            {...register("email", { required: true })}
            error={!!errors.email}
            fullWidth
            placeholder={getLabels().email}
            sx={{ mb: 2, maxWidth: 550 }}
            InputProps={{ sx: { bgcolor: "#FFF" } }}
          />
          <TextField
            {...register("phoneNumber", { required: true })}
            error={!!errors.phoneNumber}
            fullWidth
            placeholder={getLabels().phoneNumber}
            sx={{ mb: 2, maxWidth: 550 }}
            InputProps={{ sx: { bgcolor: "#FFF" } }}
          />
          <TextField
            {...register("company", { required: true })}
            error={!!errors.company}
            fullWidth
            placeholder={getLabels().company}
            sx={{ mb: 2, maxWidth: 550 }}
            InputProps={{ sx: { bgcolor: "#FFF" } }}
          />
          <TextField
            {...register("rfc", { required: true })}
            error={!!errors.rfc}
            fullWidth
            placeholder={getLabels().rfc}
            sx={{ mb: 2, maxWidth: 550 }}
            InputProps={{ sx: { bgcolor: "#FFF" } }}
          />

          {Object.keys(errors).length > 0 && (
            <Box>
              <Typography color="error" variant="caption">
                *Campos obligatorios
              </Typography>
            </Box>
          )}
          {location.pathname.includes("corporativo") && (
            <Box
              sx={{
                width: "100%",
                maxWidth: 550,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 5,
              }}
            >
              <Box>
                <ControlLabel sx={{ fontSize: "14px !important", mr: 1 }} control={<Check />} label="Me interesa el plan pro" />
              </Box>
              <Box>
                <ControlLabel sx={{ fontSize: "14px !important", mr: 1 }} control={<Check />} label="Me interesa el plan premium" />
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              maxWidth: 550,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <ControlLabel
                sx={{ fontSize: "14px !important", mr: 1 }}
                control={<Check checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} />}
                label={
                  <Typography variant="body1">
                    Acepto los{" "}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleFaqs("terms");
                      }}
                    >
                      términos y condiciones
                    </a>{" "}
                    y la{" "}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleFaqs("privacy");
                      }}
                      href="#"
                    >
                      política de privacidad
                    </a>
                  </Typography>
                }
              />
            </Box>
            <Button
              variant="contained"
              sx={(theme: any) => ({
                bgcolor: props.kivit ? "#7678ED" : theme.palette.primary.main,
                px: 4,
                borderRadius: "8px",
                ml: "auto",
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                fontSize: { xs: 15, sm: 20 },
              })}
              onClick={handleSubmit(handleForm)}
            >
              Continuar
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
          sm={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <img alt="" src={props.kivit ? "/static/computerkivit.png" : "/static/pic4.png"} loading="lazy" />
        </Grid>
      </GridContainer>
      {showSuccessDialog && <SuccessDialog onClose={handleInvitationReady} email={currentEmail} />}
    </RegisterContainer>
  );
};

const SuccessDialog = ({ onClose, email }: any) => {
  const [resendEmail, setResendEmail] = useState(true);
  let timer: any;

  const handleResend = async () => {
    setResendEmail(false);
    timer = setTimeout(() => {
      setResendEmail(true);
    }, 20000);

    try {
      await fetch("https://bpm.axeleratum.com/api/v1/bpm/users/resendInvitation/" + email, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      alert("No se pudo completar la acción");
    }
  };

  useEffect(() => {
    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, []);

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogContent
        style={{
          maxWidth: "600px",
          padding: "2rem",
          textAlign: "center",
          backgroundColor: "#F5FBFF",
          borderRadius: "20px",
          position: "relative",
        }}
      >
        <Typography
          style={{ textAlign: "center", fontStyle: "normal", fontSize: "45px", lineHeight: "49px", color: "#03264C", marginTop: "2rem" }}
        >
          Gracias por llenar el formulario
        </Typography>
        <Typography
          style={{ textAlign: "center", fontStyle: "normal", fontSize: "21px", lineHeight: "23px", color: "#03264C", marginTop: "2rem" }}
        >
          En breve recibirás un correo para poder acceder a nuestra plataforma. Revisa la bandeja de entrada del correo que nos
          proporcionaste.
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "15px",
            color: "#03264C80",
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          Si no encuentras el correo, por favor revisa la bandeja de no deseado/spam o si no da clic en el siguiente botón
        </Typography>
        <Button
          onClick={handleResend}
          disabled={!resendEmail}
          sx={(theme: Theme) => ({
            color: "#fff",
            minWidth: 142,
            backgroundColor: "#52C6BA",
            px: 2,
            borderRadius: "8px",
            fontSize: { xs: 15, sm: 20 },
            textTransform: "none",
            "&:hover": {
              backgroundColor: alpha("#52C6BA", 0.8),
            },
          })}
        >
          Volver a enviar correo
        </Button>
        <Button
          onClick={onClose}
          variant="text"
          color="inherit"
          style={{ position: "absolute", top: "1rem", right: "1rem", fontSize: "1rem" }}
        >
          X
        </Button>
      </DialogContent>
    </Dialog>
  );
};
