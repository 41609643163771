import React, { useCallback } from "react";
import { Box, Divider, IconButton, Typography, useTheme,Modal } from "@mui/material";
import { alpha, styled } from "@mui/system";
import { useLocation, Link as Lk } from "react-router-dom";
import { routeNames } from "../utils/nav-items";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TermsDialog from "../components/TermsDialog";
import {Privacy} from "../components/Privacy";
import SecurityPoliticDialog from "./SecurityPoliticDialog";

const FooterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: 200,
  overflow: "hidden",
  backgroundImage: "linear-gradient(101deg, #E8E8E8 0%, #E8F5F9 100%)",
  padding: "30 120px",
  [theme.breakpoints.up("xl")]: {
    padding: "30px 250px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "30px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "30px 30px",
  },
  [theme.breakpoints.between(650, 875)]: {
    padding: "30px 80px",
  },
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  [theme.breakpoints.down(875)]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down(439)]: {
    alignItems: "center",
    textAlign: "center",
  },
}));
const StyledBox = styled(Box)({
  flex: 1,
});

const Link = styled("a")(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: 5,
  display: "block",
  "&:hover": {
    cursor: "pointer",
  },
}));

const NavItemLink = styled(Lk)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  display: "flex",
  marginBottom: 5,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 110,
  },
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const NavItemLinkLine = styled(Lk)(({ theme }) => ({
  fontWeight: 700,
  textDecoration: "none",
  color: theme.palette.text.primary,
  wordWrap: "break-word",
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 110,
  },
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const MiniLink = styled("a")(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.text.primary,
  marginBottom: 5,
  display: "block",
  "&:hover": {
    cursor: "pointer",
  },
}));

const SocialContainer = styled(Box)(({ theme }) => ({
  "& a": {
    textAlign: "center",
    paddingTop:'1rem'
  },
  [theme.breakpoints.down(440)]: {
    position: "static",
  },
  [theme.breakpoints.between(440, 875)]: {
    position: "absolute",
    right: 0,
  },
}));

const TermsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const Footer = (props: any) => {
  const theme = useTheme();
  const location = useLocation();
  const [openPrivacy, setopenPrivacy] = React.useState(false);
  const [openSecurity, setopenSecurity] = React.useState(false);
  const [openTerms, setopenTerms] = React.useState(false);

  const getLogo = useCallback(() => {
    const ph = location.pathname;
    if (
      ph.startsWith(`/${routeNames.home}`) ||
      ph.startsWith(`/${routeNames.corpMicrosoft}`) ||
      ph.startsWith(`/${routeNames.corp}`) ||
      ph.startsWith(`/${routeNames.pymeMicrosoft}`) ||
      ph.startsWith(`/${routeNames.pyme}`)
    ) {
      return {
        asset: "/static/kivit2.png",
        asset2: "/static/kivit3.png",
        hash: "#home",
      };
    } else {
      return {
        asset: "/static/LOGO.svg",
        asset2: "",
        hash: "#home",
      };
    }
  }, [location.pathname]);


  return (
    <FooterContainer>
      <LinksContainer>
        <StyledBox
          sx={{
            flexGrow: location.pathname === "/" ? 0 : 1,
            marginRight: location.pathname === "/" ? { xs: 0, sm: 10, md: 15 } : 0,
          }}
        >
          <img alt="" src={getLogo().asset} style={{ marginBottom: 40, maxWidth: 160, marginTop: 20 }} />
        </StyledBox>
     
        <StyledBox >
          <List sx={{ display: 'block' }}>
          <ListItem sx={{ display: 'block' }}>
          <Typography
            sx={{
              color: "primary.main",
              letterSpacing: 2,
              fontWeight: 500,
            }}
          >
            MENÚ
          </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/#home'}>
                {'Home'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/#benefits'}>
                {'Beneficios'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/#contact'}>
                {'Contacto'}
              </NavItemLinkLine>
            </ListItem>
            {/**  <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/'}>
                {'Blog'}
              </NavItemLinkLine>
            </ListItem> */}  
            <ListItem sx={{ display: 'block' }}>
              <NavItemLink to={'/'} onClick={() => window.location.assign("https://kivit.axeleratum.com/")}>
                {'Iniciar Sesión'}
              </NavItemLink>
            </ListItem>
          </List>
        </StyledBox>

        <StyledBox >
          <List sx={{ display: 'block' }}>
          <ListItem sx={{ display: 'block' }}>
          <Typography
            sx={{
              color: "primary.main",
              letterSpacing: 2,
              fontWeight: 500,
            }}
          >
            PROOVEDOR
          </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/proveedor'}>
                {'Inicio'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/proveedor#repse'}>
                {'REPSE'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/proveedor#works'}>
                {'¿Como funciona?'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/proveedor#benefits'}>
                {'Detalles del plan'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLink to={'/#contact'}>
                {'Pide tu DEMO'}
              </NavItemLink>
            </ListItem>
          </List>
        </StyledBox>

        <StyledBox>
          <List sx={{ display: 'block' }}>
          <ListItem sx={{ display: 'block' }}>
          <Typography
            sx={{
              color: "primary.main",
              letterSpacing: 2,
              fontWeight: 500,
            }}
          >
            CORPORATIVO
          </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/corporativo'}>
                {'Inicio'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/corporativo#responsable'}>
                {'Responsable solidario'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/corporativo#benefits'}>
                {'Características'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/corporativo#banner'}>
                {'Detalles del plan'}
              </NavItemLinkLine>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLink to={'/#contact'}>
                {'Cotiza tu plan'}
              </NavItemLink>
            </ListItem>
          </List>
        </StyledBox>

       {/*   <StyledBox>
          <List sx={{ display: 'block' }}>
          <ListItem sx={{ display: 'block' }}>
          <Typography
            sx={{
              color: "primary.main",
              letterSpacing: 2,
              fontWeight: 500,
            }}
          >
            CONSTRUCCIÓN
          </Typography>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
              <NavItemLinkLine to={'/#home'}>
                {'Inicio'}
              </NavItemLinkLine>
            </ListItem>
          </List>
        </StyledBox> */}
     
       
        <SocialContainer
          sx={(theme: any) => ({
            [theme.breakpoints.between(440, 875)]: {
              position: "absolute",
              bottom: location.pathname !== "/" && location.pathname !== routeNames.pyme ? 24 : "auto",
            },
          })}
        >
          <img alt="" src={getLogo().asset2} style={{ maxWidth: 120, margin: "0 auto 10px auto" }} />
         
          <Box>
            <Link sx={{ fontSize: 12 }}>www.axeleratum.com</Link>
            <Link sx={{ fontSize: 12 }}>info@axeleratum.com</Link>
          </Box>
         
          <Box sx={{pt:1,mt:1}}>
           <IconButton onClick={() => window.location.assign("https://www.linkedin.com/company/kivitmx/")}>
            <img alt="" src="/static/iconLK.png" style={{width:'40px'}}/>
            </IconButton>
            <IconButton onClick={() => window.location.assign("https://www.instagram.com/kivit_mx/")}>
              <img alt="" src="/static/iconIG.png"  style={{width:'40px'}}/>
            </IconButton>
            <IconButton onClick={() => window.location.assign("https://www.youtube.com/@axeleratum3118")}>
              <img alt="" src="/static/iconYT.png"  style={{width:'40px'}}/>
            </IconButton>
          </Box>
        
        </SocialContainer>

      </LinksContainer>
      <Divider
        variant="fullWidth"
        sx={{
          marginBottom: "30px",
          height: "30px",
          borderColor: alpha(theme.palette.primary.main, 0.3),
          borderBottomWidth: 3,
        }}
      />
      <TermsContainer>
        <Typography align="center" sx={{ fontSize: 12, color: "#888",marginTop:'1rem'  }}>
          Copyright {new Date().getFullYear()} Axeleratum. Todos los derechos reservados.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center",marginTop:'1rem'  }}>
          <Typography align="center" sx={{ fontSize: 12, color: "#888"}}>
            Partnerns with: 
          </Typography>
          <Box>
            <img src={'/static/microsoft.png'} alt='LogoM' style={{width:'75px',paddingLeft:'2px'}}></img>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center",marginTop:'1rem' }}>
        <MiniLink
           onClick={() => setopenTerms(true)}
            sx={{
              textDecoration: "underline",
              color: "#777",
              marginRight: { xs: 3, sm: 4, md: 7 },
              textAlign: "center",
            }}
          >
            Términos y condiciones
          </MiniLink>
          <MiniLink
           onClick={() => setopenSecurity(true)}
            sx={{
              textDecoration: "underline",
              color: "#777",
              marginRight: { xs: 3, sm: 4, md: 7 },
              textAlign: "center",
            }}
          >
           Política de seguridad
          </MiniLink>
          <MiniLink
          onClick={() => setopenPrivacy(true)}
            sx={{
              textDecoration: "underline",
              color: "#777",
              textAlign: "center",
              paddingBottom: 5
            }}
            //onClick={props.handleOpenPrivacy}
          >
            Aviso de privacidad de datos
          </MiniLink>
        </Box>
      </TermsContainer>
      {openTerms && <TermsDialog open={openTerms} onClose={() => setopenTerms(false)} />}
      {openSecurity && <SecurityPoliticDialog open={openSecurity} onClose={() => setopenSecurity(false)} />}

      {openPrivacy && (
        <Modal open={true} onClose={() => setopenPrivacy(false)}>
          <Privacy onClose={() => setopenPrivacy(false)} />
        </Modal>
      )}
    </FooterContainer>
  );
};
