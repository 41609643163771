import { Box, Button, Typography, styled } from "@mui/material";
import React from "react";

const BodyText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px !important",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 18,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 18,
  },
  [theme.breakpoints.up("lg")]: {
    width: "80%",
  },
}));

export const ContentSecondSection = () => {
  return (
    <>
      <Typography variant="h4" sx={{ mb: 5 }}>
        ¿Cómo puedes evitar posibles multas o sanciones administrativas?
      </Typography>
      <BodyText variant="body2">
        Con el propósito de regularizar las prácticas de outsourcing el gobierno
        implementó una reforma laboral en la que como proveedor de servicios
        tienes la obligación de estar registrado en el REPSE (Registro de
        Prestadores de Servicios u Obras Especializadas).
      </BodyText>
      <Box
        sx={{
          display: "flex",
          width: { lg: "80%" },
          justifyContent: "space-between",
          alignItems: "center",
          height: 50,
          my: 3,
        }}
      >
        <BodyText variant="body2">¿Aún no estás registrado?</BodyText>
        <a
          href="https://repse.stps.gob.mx/F"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ minWidth: 140, textTransform: "none", ml: 1 }}
          >
            Ir a Registrarme
          </Button>
        </a>
      </Box>
      <BodyText variant="body2">
        Nosotros aseguramos la trazabilidad de tus operaciones almacenando,
        ordenando y verificando tus documentos, de tal manera que identificamos
        de manera oportuna los riesgos fiscales y legales para que puedas tomar
        las acciones necesarias para estar dentro del marco de legalidad ante
        autoridades fiscales y auditorías.
      </BodyText>
    </>
  );
};
