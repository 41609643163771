import React, { FC } from "react";
import { Box, styled } from "@mui/system";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { WhatsApp, PhoneOutlined, EmailOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import FormularioMonday from "./FormularioMondat";

interface FormData {
  name: string;
  lastName: string;
  email: string;
  company: string;
  number: string;
}

interface ContactSectionProps {
  form?: boolean;
  email?: string;
  numbers?: string;
  img?: string;
  wspIcon?: boolean;
}

const ContactContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  padding: "0 30px",
  [theme.breakpoints.up("xl")]: {
    padding: "50px 270px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "50px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "50px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "50px 40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 30px 50px 30px",
  },
}));

const ContactForm = styled(Box)(({ theme }) => ({
  width: "100",
  [theme.breakpoints.down("md")]: {
    width: "200",

  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "-12rem",
    width: "30",
  },
}));
const GridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

export const ContactSection: FC<ContactSectionProps> = ({wspIcon = true, ...props}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const ref = React.useRef<HTMLDivElement>();
  const location = useLocation();

  const handleForm = (formData: FormData) => {
    console.log(formData);
  };

  React.useEffect(() => {
    if (location.hash === "#contact") {
      if (ref?.current) {
        ref?.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <ContactContainer ref={ref} id="contact">
      <GridContainer container>
        <Grid
          item
          xs={12}
          sm={6}
          sx={
            !props.form
              ? {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
              : undefined
          }
        >
              <ContactForm>       <FormularioMonday/>
</ContactForm>

      </Grid>

        <Grid
          item
          xs={9}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <img
            alt="img-form"
            src={"/static/contact-kivit1.png"}
          />
        </Grid>
        <>
          <Typography sx={{ display: "flex", alignItems: "center", color: "#001633" }} >
            <PhoneOutlined sx={{ ml: 3, mr:1 }} />
            <span style={{ marginRight: '-5px' }}>{" 55 50 93 65 26 "}</span>
          </Typography>
          <Typography sx={{ display: "flex", alignItems: "center", color: "#001633"  }}>
            <WhatsApp sx={{ ml: 3 , mr:1}} />
            <span style={{ marginRight: '-5px' }}>{"55 80 97 14 27"}</span>
          </Typography>
          <Typography sx={{ display: "flex", alignItems: "center", color: "#001633"  }}>
            <EmailOutlined sx={{ ml: 3,mr:1 }} />
            <span style={{ marginRight: '-5px' }}>{props.email}</span>
          </Typography>
       </>
      </GridContainer>
    </ContactContainer>
  );
};
