import React, { FC } from "react";
import { alpha, Box, Button, Drawer as MuiDrawer } from "@mui/material";
import { TryButton } from "./TryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { appRoutes } from "../App";
import { routeNames } from "../utils/nav-items";

interface DrawerProps {
  open: boolean;
  handleClose: () => void;
}

export const Drawer: FC<DrawerProps> = (props) => {
  const { open, handleClose } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const getNavItems = () => {
    if (location.pathname === "/" || location.pathname === "/kivit-blog") {
      return appRoutes[0];
    }
    const navs = appRoutes.find((route: any) => route.path === location.pathname.slice(1));
    console.log(navs);
    return navs ?? appRoutes[0];
  };

  const getButtons = () => {
    const ph = location.pathname;
    if (ph.startsWith(`/${routeNames.pymeMicrosoft}`)) {
      return {
        firstText: "Pide tu Demo",
        //firstLink: `/${routeNames.pymeMicrosoft}/registro`,
        firstLink: `/#contact`,
        firstColor: "#7678ED",
        secondText: "Iniciar sesión",
        secondLink: "https://kivit.axeleratum.com/",
        secondColor: "#52C6BA",
      };
    }
    if (ph.startsWith(`/${routeNames.pyme}`)) {
      return {
        firstText: "Pide tu Demo",
       // firstLink: `/${routeNames.pyme}/registro`,
        firstLink: `/#contact`,
        firstColor: "#7678ED",
        secondText: "Iniciar sesión",
        secondLink: "https://kivit.axeleratum.com/",
        secondColor: "#52C6BA",
      };
    }
    if (ph.startsWith(`/${routeNames.corpMicrosoft}`)) {
      return {
        firstText: "Cotiza tu plan",
        firstLink: `/#contact`,
        firstColor: "#7678ED",
        secondText: "Iniciar sesión",
        secondLink: "https://kivit.axeleratum.com/",
        secondColor: "#52C6BA",
      };
    }
    if (ph.startsWith(`/${routeNames.corp}`)) {
      return {
        firstText: "Cotiza tu plan",
        firstLink: `/#contact`,
        firstColor: "#7678ED",
        secondText: "Iniciar sesión",
        secondLink: "https://kivit.axeleratum.com/",
        secondColor: "#52C6BA",
      };
    }
    if (ph.startsWith(`/${routeNames.home}`)) {
      return {
        firstText: "Soy Proveedor",
        firstLink: `/${routeNames.pyme}`,
        firstColor: "#7678ED",
        secondText: "Soy Corporativo",
        secondLink: `/${routeNames.corp}`,
        secondColor: "#52C6BA",
      };
    }
    return {
      firstText: "Pide tu demo",
      firstLink: `/#contact`,
      firstColor: "#E59B4E",
      secondText: "Iniciar Sesión",
      secondLink: "https://kivit.axeleratum.com/",
      secondColor: "#0092F6",
    };
  
  };

  return (
    <MuiDrawer open={open} onClose={handleClose} anchor="right">
      <Box
        sx={{
          py: 3,
          px: 3,
          width: 280,
          height: "100%",
          backgroundImage: "url(/static/main-background.svg)",
          backgroundSize: "cover",
        }}
      >
        <a
          href="#home"
          onClick={(e: any) => {
            navigate("/#home");
            handleClose();
          }}
        >
          <img
            alt=""
            src={location.pathname !== "/v0" && location.pathname !== "/pyme-v0" ? "/static/kivit.png" : "/static/LOGO.svg"}
            style={{ margin: "0 auto 30px auto", maxWidth: 200 }}
          />
        </a>
        {getNavItems().navItems.map((item: any) => (
          <a
            key={item.hash}
            style={{ textDecoration: "none" }}
            onClick={(e: any) => {
              e.preventDefault();
              if(location.pathname !== '/kivit-blog'){
                navigate(`${location.pathname}${item.hash}`);
              }else{
                navigate(`/${item.hash}`);
              }
              handleClose();
            }}
            href={`${location.pathname}${item.hash}`}
          >
            <Button fullWidth sx={{ color: "text.primary", my: 2 }}>
              {item.name}
            </Button>
          </a>
        ))}
       {/**<a
          style={{ textDecoration: "none" }}
          onClick={(e: any) => {
            e.preventDefault();
            navigate(`/kivit-blog`);
            handleClose();
          }}
          href={"/kivit-blog"}
        >
          <Button fullWidth sx={{ color: "text.primary", my: 2 }}>
            Blog
          </Button>
        </a>*/} 
        <TryButton
          fullWidth
          sx={{
            backgroundColor: getButtons().firstColor,
            "&:hover": {
              backgroundColor: alpha(getButtons().firstColor, 0.8),
            },
            marginTop: 2,
          }}
          onClick={() => {
            handleClose();
            navigate(getButtons().firstLink);
          }}
          text={getButtons().firstText}
        />
        {getButtons().secondText.includes("Iniciar") ? null : getButtons().secondLink.includes("http") ? (
          <a
            style={{ textDecoration: "none" }}
            href={getButtons().secondLink}
            /* target="_blank" */
            rel="noreferrer"
          >
            <TryButton
              sx={{
                backgroundColor: getButtons().secondColor,
                "&:hover": {
                  backgroundColor: alpha(getButtons().secondColor, 0.8),
                },
                marginTop: 2,
              }}
              text={getButtons().secondText}
              fullWidth
              onClick={handleClose}
            />
          </a>
        ) : (
          <TryButton
            fullWidth
            sx={{
              backgroundColor: getButtons().secondColor,
              "&:hover": {
                backgroundColor: alpha(getButtons().secondColor, 0.8),
              },
              marginTop: 2,
            }}
            onClick={() => {
              handleClose();
              navigate(getButtons().secondLink);
            }}
            text={getButtons().secondText}
          />
        )}
      </Box>
    </MuiDrawer>
  );
};
