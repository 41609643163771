import React from "react";
import { Box, Typography } from "@mui/material";

export const ContentSecondSection = () => {
  return (
    <Box>
      <Typography
        variant="h3"
        sx={(theme: any) => ({
          mb: 7,
        })}
      >
        ¿Por qué es importante?
      </Typography>
      <Typography variant="body2" sx={{ maxWidth: 550, mb: 3 }}>
        Una empresa que contrata un servicio especializado se vuelve
        “responsable solidario” de esos trabajadores y de las obligaciones
        legales y fiscales que el contratista incumpla.
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 700, mb: 3 }}>
        ¡Evita sanciones y multas millonarias!
      </Typography>
      <Typography variant="body2" sx={{ maxWidth: 550 }}>
        Con nuestra plataforma estarás preparado para atender cualquier
        auditoría al conocer el cumplimiento de tus proveedores ante el IMSS,
        SAT, Infonavit y STPS.
      </Typography>
    </Box>
  );
};
