import React from "react";
import { Box, Typography } from "@mui/material";

export const BenefitsContent = () => {
  return (
    <Box>
      <Typography variant="h3" sx={{ mb: 5 }}>
        ¿Por qué es para mí?
      </Typography>
      {benefitsArray.map((ben: string, i: number) => (
        <Box
          key={ben.slice(0, 7)}
          sx={{ mb: 3, display: "flex", alignItems: "center" }}
        >
          <img
            style={{ width: "50px", marginBottom: 10 }}
            alt=""
            src={`/static/${i + 1}.png`}
          />
          <Typography sx={{ ml: 2 }}>{ben}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const benefitsArray = [
  "Valida y verifica los documentos de forma automatizada (robots) para darcerteza del cumplimento ante la Ley Federal del Trabajo.",
  "Te ayuda a identificar los posibles riesgos que podrían traducirse en multas y/ o sanciones millonarias.",
  "Es un aliado para responder ante cualquier auditoría gracias a nuestro sistema automatizado.",
  "Con la automatización de procesos el personal puede enfocarse en un trabajo más estratégico.",
  "Guarda el historial de los movimientos realizados en los documentos (trazabilidad).",
  "Recibes notificaciones sobre el estatus de los documentos.",
  "Garantiza la seguridad informática e inmutabilidad en tu información gracias a nuestra tecnología Blockchain.",
  "Desarrollada por Axeleratum, expertos en Inteligencia Artificial.",
];
