import React from "react";
import { Banner } from "../../components/Banner";
import { ContactSection } from "../../components/ContactSection";
import { MainSection } from "../../components/MainSection";
import { SecondSection } from "../../components/SecondSection";
import { BenefitsSection } from "../../components/BenefitsSection";
import { ContentMainSection } from "./ContentMainSection";
import { ContentSecondSection } from "./ContentSecondSection";
import { WorksSection } from "../../components/WorksSection";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import usePageGa from "../../hooks/usePageGa";

export const Pyme = () => {
  useScrollToTop();
  usePageGa()

  return (
    <>
      <MainSection link="https://www.youtube.com/watch?v=NxIoTKtMdNs&t=16s">
        <ContentMainSection />
      </MainSection>
      <SecondSection idHash="repse">
        <ContentSecondSection />
      </SecondSection>
      <SecondSection
        idHash="works"
        reverse
        reverseColumn
        img="funciona.png"
        styleImg={{ transform: "scale(100%)" }}
      >
        <WorksSection />
      </SecondSection>
      <Banner />
      <BenefitsSection />
      <ContactSection email="info@kivit.mx" numbers="558 097 14 27" />
    </>
  );
};
