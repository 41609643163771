import React from 'react';
import {Grid} from "@mui/material";
import { styled } from "@mui/system";

const GridContainer = styled(Grid)(({ theme }) => ({
    width: "700%",
    height:"800%",
  
    [theme.breakpoints.down("md")]: {
        width: "350%",
        height:"700%"
    },
    [theme.breakpoints.down("sm")]: {
        width: "300%",
        height:"700%"
    },
  }));

const FormularioMonday = () => {
  return (
    <iframe
        src="https://forms.monday.com/forms/embed/5b25e8236d28c6ee78ae1d0d5be312d0?r=use1"
        width= "650"
    height="1110"
        loading="lazy"
       // width="350"
        style={{ border: '0', boxShadow: '5px 5px 5px 5px rgba(#ffff)' }}
        title="Formulario Monday.com"
    >
    </iframe>
  );
};

export default FormularioMonday;

