import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { MainSection } from "../../components/MainSection";
import { SecondSection } from "../../components/SecondSection";
import { Banner } from "../../components/Banner";
import { BenefitsSection } from "../../components/BenefitsSection";
import { ContactSection } from "../../components/ContactSection";
import { ContentMainSection } from "./ContentMainSection";
import { ContentSecondSection } from "./ContentSecondSection";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import usePageGa from "../../hooks/usePageGa";

const HomeContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "whitesmoke",
  width: "100%",
}));

export const Home = () => {
  useScrollToTop();
  usePageGa()

  return (
    <HomeContainer>
      <MainSection link="https://www.youtube.com/watch?v=NxIoTKtMdNs&t=16s">
        <ContentMainSection />
      </MainSection>
      <SecondSection idHash="repse">
        <ContentSecondSection />
      </SecondSection>
      <Banner />
      <BenefitsSection />
      <ContactSection form />
    </HomeContainer>
  );
};
