import React from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { MainButton } from "../../components/MainButton";
import { routeNames } from "../../utils/nav-items";
import VideoPlayer from "./VideoRender";

export const ContentMainSection = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  
  return (
    <>
      {smScreen ? (
        <Typography variant="h2" sx={{ mb: 3 }} align="left">
          ¡Asegura tu{" "}
          <span style={{ fontWeight: 600 }}>cumplimiento en un solo clic!</span>
        </Typography>
      ) : (
        <>
          <Typography variant="h2">
            ¡Asegura tu <span style={{ fontWeight: 600 }}>cumplimiento</span>
          </Typography>
          <Typography variant="h2" sx={{ mb: 3, fontWeight: 600 }}>
            en un solo clic!
          </Typography>
        </>
      )}

      <Typography
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            lg: "80%",
          },
          fontWeight: 500,
          mb: 3,
        }}
        variant="body2"
      >
        Somos una plataforma digital automatizada que administra, verifica y
        resguarda los documentos asociados al cumplimiento con la reforma
        laboral. Verificar que una empresa es cumplida es muy fácil.
      </Typography>
      <Typography
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            lg: "80%",
          },
          fontWeight: 500,
          mb: 3,
        }}
        variant="body2"
      >
        Ideal para quienes contratan y/o proveen servicios especializados y
        obras complementarias (outsourcing) registrados en la plataforma REPSE.
      </Typography>
      <Typography
        sx={{
          width: {
            xs: "100%",
            sm: "80%",
            lg: "80%",
          },
          fontWeight: 500,
        }}
        variant="body2"
      >
        ¡Averigua cuál es el plan perfecto para ti!
      </Typography>
      {/*<VideoPlayer />*/}
      <Box
        sx={(theme: any) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
          },
        })}
      >
      <Box sx={{ display: "flex", gap: 2 , marginLeft: "-10px"}}>
        <MainButton
            type="fill"
            color="purple"
            link={`/${routeNames.pyme}`}
            text="Soy Proveedor"
        />
        <MainButton
            type="fill"
            color="green"
            link={`/${routeNames.corp}`}
            text="Soy Corporativo"
        />
        </Box>
        
        {/** <MainButton
          link={`/${routeNames.home}`}
          text="Construcción"
        /> */}
       
      </Box>
    </>
  );
};
